import { FxSessionManagementActionType } from '@3lgn/shared/dist/constants/fx';
import * as yup from 'yup';
import { ExpertOrderDirection, ExpertOrderType } from '@root/modules/experts/types/common';

import { SymbolType } from '@root/modules/accounts/enums';

const getStrategyType = (context): string | undefined => {
  return context.from[context.from.length - 1].value.strategy.type;
};

const getAllowedSymbolType = (context): string | undefined => {
  return context.from[context.from.length - 1].value.meta?.allowedSymbolType;
};

export const createExpertValidation = (t, yupT) =>
  yup.object().shape({
    account: yup.string().required().label(t('fields.account.text')),
    name: yup.string().required().label(t('fields.name.text')),
    description: yup.string().label(t('fields.description.text')),
    providerId: yup.string().required().label(t('fields.providerId.text')),
    symbols: yup
      .array()
      .of(yup.string().required().label(t('fields.symbols.text')))
      .min(1)
      .test('has-xau', t('fields.symbols.xauOnly'), (symbols, context) => {
        const allowedSymbolType = getAllowedSymbolType(context);
        return !((symbols || [])?.length > 1 && symbols?.includes('XAUUSD') && allowedSymbolType !== SymbolType.CURRENCY);
      })
      .required()
      .label('Symbols'),
    usePips: yup.boolean().required().label(t('fields.usePips.label')),
    slippage: yup
      .number()
      .min(0)
      .max(10_000)
      .typeError(t('fields.slippage.typeError'))
      .test('is-decimal', yupT('number.integersOnly'), (value) => !`${value}`.includes('.'))
      .when('useSlippage', {
        is: true,
        then: (schema) => schema.required(),
      })
      .label(t('fields.slippage.label')),
    strategy: yup.object().shape({
      type: yup.string().required().label(t('fields.strategyType.label')),
      usePriceTolerance: yup.boolean().required().label(t('fields.priceTolerance.label')),
      timeTolerance: yup.number().when('usePriceTolerance', {
        is: true,
        then: (schema) =>
          schema
            .integer()
            .min(0)
            .when('timeToleranceType', {
              is: 0,
              then: (schema) => schema.max(21600, t('fields.timeTolerance.maxSec', { max: 21600 })),
            })
            .when('timeToleranceType', {
              is: 1,
              then: (schema) => schema.max(360, t('fields.timeTolerance.maxMin', { max: 360 })),
            })
            .typeError(yupT('number.type'))
            .required()
            .label(t('fields.expiryTime.label')),
      }),
      timeToleranceType: yup.number().integer(),
      priceTolerance: yup
        .number()
        .when('usePriceTolerance', {
          is: true,
          then: (schema) => schema.integer().moreThan(0).typeError(yupT('number.type')).required(),
        })
        .label(t('fields.priceTolerance.label')),
      usePostSignalProximity: yup.boolean().required().label(t('fields.postSignalValidation.label')),
      tpProximity: yup
        .number()
        .when('usePostSignalProximity', {
          is: true,
          then: (schema) => schema.integer().moreThan(0).required(),
        })
        .label(t('fields.tpProximity.label')),
    }),
    maxManualActiveTrades: yup
      .number()
      .typeError(yupT('number.type'))
      .min(1, yupT('number.min'))
      .max(9, t('fields.manualMoneyManagement.maxActiveTrades.maxError'))
      .test('is-decimal', yupT('number.integersOnly'), (value) => `${value}`.length === 1)
      .when('strategy.type', {
        is: 'manual',
        then: (schema) => schema.required(),
      })
      .label(t('fields.manualMoneyManagement.maxActiveTrades.label')),
    maxSignalActiveTrades: yup
      .number()
      .typeError(yupT('number.type'))
      .min(1, yupT('number.min'))
      .max(9, t('fields.manualMoneyManagement.maxActiveTrades.maxError'))
      .test('is-decimal', yupT('number.integersOnly'), (value) => `${value}`.length === 1)
      .when('strategy.type', {
        is: 'manual',
        then: (schema) => schema.required(),
      })
      .label(t('fields.manualMoneyManagement.maxActiveTrades.label')),

    manualMoneyManagement: yup.object().shape({
      type: yup
        .number()
        .test((value, context) => {
          return getStrategyType(context) === 'manual' ? typeof value === 'number' : true;
        })
        .label(t('fields.manualMoneyManagement.type.label')),
      lotsize: yup
        .number()
        .typeError(yupT('number.type'))
        .when('type', {
          is: (value) => [0, 2].includes(value),
          then: (schema) => schema.required().moreThan(0),
        })
        .label(t('fields.manualMoneyManagement.lotsize.label')),
      riskPercent: yup
        .number()
        .min(0)
        .max(5)
        .typeError(yupT('number.type'))
        .when('type', {
          is: 1,
          then: (schema) => schema.required().moreThan(0),
        })
        .label(t('fields.manualMoneyManagement.riskPercent.shortLabel')),
      basedOn: yup
        .number()
        .typeError(yupT('number.type'))
        .when('type', {
          is: (value) => [1, 2].includes(value),
          then: (schema) => schema.required(),
        })
        .label(t('fields.manualMoneyManagement.basedOn.label')),
      k: yup
        .number()
        .typeError(yupT('number.type'))
        .when('type', {
          is: 2,
          then: (schema) => schema.required().moreThan(0),
        })
        .label(t('fields.manualMoneyManagement.k.shortLabel')),
    }),
    signalMoneyManagement: yup.object().shape({
      type: yup
        .number()
        .min(0)
        .typeError(yupT('number.type'))
        .test('Required', (value, context) => {
          return getStrategyType(context) === 'signal' ? typeof value === 'number' : true;
        })
        .label(t('fields.signalMoneyManagement.k.label')),
      lotsize: yup
        .number()
        .min(0)
        .typeError(yupT('number.type'))
        .when('type', {
          is: (value) => [0, 2].includes(value),
          then: (schema) => schema.required().moreThan(0),
        })
        .label(t('fields.signalMoneyManagement.type.label')),
      riskPercent: yup
        .number()
        .min(0)
        .max(100)
        .typeError(yupT('number.type'))
        .when('type', {
          is: (value) => [1, 3].includes(value),
          then: (schema) => schema.required().moreThan(0),
        })
        .label(t('fields.signalMoneyManagement.riskPercent.shortLabel')),
      basedOn: yup
        .number()
        .min(0)
        .typeError(yupT('number.type'))
        .when('type', {
          is: (value) => [1, 2, 3, 4].includes(value),
          then: (schema) => schema.required(),
        })
        .label(t('fields.signalMoneyManagement.basedOn.label')),
      k: yup
        .number()
        .min(0)
        .typeError(yupT('number.type'))
        .when('type', {
          is: 2,
          then: (schema) => schema.required().moreThan(0),
        })
        .label(t('fields.signalMoneyManagement.k.shortLabel')),
      xPercent: yup
        .number()
        .min(0)
        .typeError(yupT('number.type'))
        .when('type', {
          is: (value) => [3, 4].includes(value),
          then: (schema) => schema.required().moreThan(0),
        })
        .label(t('fields.signalMoneyManagement.xPercent.label')),
    }),
    breakEven: yup.object().shape({
      use: yup.boolean().required().label(t('fields.breakEven.use.label')),
      levels: yup
        .array()
        .when('use', {
          is: true,
          then: (schema) =>
            schema
              .of(
                yup.object().shape({
                  afterX: yup
                    .number()
                    .min(0)
                    .typeError(yupT('number.type'))
                    .required()
                    .moreThan(0)
                    .integer()
                    .label(t('fields.breakEven.levels.afterX.label')),
                  setToY: yup
                    .number()
                    .typeError(yupT('number.type'))
                    .required()
                    .integer()
                    .min(-30)
                    .label(t('fields.breakEven.levels.setToY.label'))
                    .when('afterX', (afterX, schema) =>
                      schema.test({
                        name: 'afterYShouldBeLessThanafterX',
                        message: t('fields.breakEven.levels.distanceMoreStopLoss'),
                        test: function (afterY) {
                          return !afterX || !afterY || afterY < afterX;
                        },
                      }),
                    ),
                }),
              )
              .min(1)
              .max(30)
              // @ts-ignore
              .when('use', (val, schema, context) => {
                if (context.from && context.from.length) {
                  const use = context.from[context.from.length - 1]?.value?.breakEven?.use;
                  const levels = context.from[context.from.length - 1]?.value?.breakEven?.levels;
                  if (use) {
                    return schema.test('distance-are-sorted', t('fields.breakEven.levels.sortedDistances'), () => {
                      let sorted = true;

                      levels?.forEach((item, index, arr) => {
                        if (index > 0 && (Number(item['afterX']) || 0) < (Number(arr[index - 1]['afterX']) || 0)) {
                          sorted = false;
                        }
                      });
                      return sorted;
                    });
                  }

                  return schema;
                }
                return schema;
              }),
        })
        .label(t('fields.breakEven.levels.label')),
    }),
    partialClose: yup.object().shape({
      use: yup.boolean().required().label(t('fields.partialClose.use.label')),
      takeprofits: yup
        .array()
        .when('use', {
          is: true,
          then: (schema) =>
            schema
              .of(
                yup.object().shape({
                  amount: yup.number().min(0).integer().typeError(yupT('number.type')).required().label(t('fields.partialClose.levels.amount.label')),
                  percent: yup.number().min(0).integer().typeError(yupT('number.type')).required().max(100).label(t('fields.partialClose.levels.percent.label')),
                }),
              )
              .min(1)
              .max(30)
              // @ts-ignore
              .when('calculationType', (val, schema, context) => {
                if (context.from && context.from.length) {
                  const calculationType = context.from[context.from.length - 1]?.value.partialClose?.calculationType;

                  return schema
                    .test('distance-are-sorted', t('fields.partialClose.levels.sortedDistances'), (list) => {
                      let sorted = true;

                      list?.forEach((item, index, arr) => {
                        if (index > 0 && (item['amount'] || 0) < (arr[index - 1]['amount'] || 0)) {
                          sorted = false;
                        }
                      });
                      return sorted;
                    })
                    .test(
                      'sum-of-percentage',
                      calculationType === '0' ? t('fields.partialClose.levels.percentageLimit') : t('fields.partialClose.levels.closePercentageLimit'),
                      (list) => {
                        const sum =
                          list?.reduce((acc, item) => {
                            return acc + (Number(item?.percent) || 0);
                          }, 0) || 0;
                        return sum <= 100;
                      },
                    );
                }
                return schema;
              }),
        })
        .label(t('fields.partialClose.levels.label')),
    }),
    manualSlTp: yup.object().shape({
      type: yup
        .number()
        .min(0)
        .typeError(yupT('number.type'))
        .test((value, context) => {
          return getStrategyType(context) === 'manual' ? typeof value === 'number' : true;
        })
        .label(t('fields.manualStopLossTakeProfit.type.label')),
      fixedSl: yup
        .number()
        .min(0)
        .typeError(yupT('number.type'))
        .test('Required', (value, context) => {
          return getStrategyType(context) === 'manual' ? typeof value === 'number' : true;
        })
        .label(t('fields.manualStopLossTakeProfit.fixedSl.label')),
      fixedTp: yup
        .number()
        .min(0)
        .typeError(yupT('number.type'))
        .when('type', {
          is: 0,
          then: (schema) => schema.required(),
        })
        .label(t('fields.manualStopLossTakeProfit.fixedTp.label')),
      tpRatio: yup
        .number()
        .typeError(yupT('number.type'))
        .when('type', {
          is: 2,
          then: (schema) => schema.required(),
        })
        .label(t('fields.manualStopLossTakeProfit.tpRatio.label')),
    }),
    signalSlTp: yup.object().shape({
      type: yup
        .number()
        .test('Required', (value, context) => {
          return getStrategyType(context) === 'signal' ? typeof value === 'number' : true;
        })
        .label(t('fields.signalStopLossTakeProfit.type.label')),
      fixedSl: yup
        .number()
        .when('type', {
          is: (value) => [0, 1].includes(value),
          then: (schema) =>
            schema
              .integer()
              .min(0)
              .typeError(yupT('number.type'))
              .test('Required', (value, context) => {
                return getStrategyType(context) === 'signal' ? typeof value === 'number' : true;
              }),
        })
        .label(t('fields.signalStopLossTakeProfit.fixedSl.label')),
      fixedTp: yup
        .number()
        .min(0)
        .integer()
        .typeError(yupT('number.type'))
        .when('type', {
          is: 0,
          then: (schema) => schema.min(5).required(),
        })
        .when(['type', 'profitCalculationType'], {
          is: (type, profitCalculationType) => {
            return type === 4 && Number(profitCalculationType) === 1;
          },
          then: (schema) => schema.min(5).required().label(t('fields.signalStopLossTakeProfit.fixedTp.smartLabel')),
        })
        .label(t('fields.signalStopLossTakeProfit.fixedTp.label')),
      tpRatio: yup
        .number()
        .min(0)
        .typeError(yupT('number.type'))
        .when('type', {
          is: (type) => [1].includes(type),
          then: (schema) => schema.required(),
        })
        .label(t('fields.signalStopLossTakeProfit.tpRatio.label')),
    }),
    trailStop: yup.object().shape({
      use: yup.boolean().required().label(t('fields.trailingStop.use.label')),
      afterX: yup
        .number()
        .min(0)
        .integer()
        .typeError(yupT('number.type'))
        .when('use', {
          is: true,
          then: (schema) => schema.required().moreThan(0),
        })
        .label(t('fields.trailingStop.fromX.label')),
      followY: yup
        .number()
        .min(0)
        .integer()
        .typeError(yupT('number.type'))
        .when('use', {
          is: true,
          then: (schema) => schema.required().typeError(yupT('number.type')).moreThan(0),
        })
        .label(t('fields.trailingStop.toY.label')),
    }),
    maxDrawDownLimit: yup.object().shape({
      use: yup.boolean(),
      percent: yup
        .number()
        .when('use', {
          is: true,
          then: (schema) => schema.required().min(1).max(100),
        })
        .label(t('fields.drawdown.percent.label')),
    }),
    sessionManagement: yup.object().shape({
      use: yup.boolean(),
      type: yup
        .string()
        .when('use', {
          is: true,
          then: (schema) => schema.required(),
        })
        .label(t('fields.sessionManagement.type.label')),
      sessions: yup
        .array()
        .when('use', {
          is: true,
          then: (schema) => schema.min(1).required(),
        })
        .label(t('fields.sessionManagement.sessions.label')),
      threshold: yup
        .number()
        .when('type', {
         is: FxSessionManagementActionType.CLOSE_IF_IN_PROFIT,
          then: (schema) => schema.required(),
        })
      .label(t('fields.sessionManagement.threshold.label')),
    }),
    conditionalClosure: yup.object().shape({
      use: yup.boolean(),
      amount: yup
        .number()
        .when('use', {
          is: true,
          then: (schema) => schema.required(),
        })
        .label(t('fields.conditionalClosure.amount.label')),
    }),
    orderType: yup.mixed().oneOf(Object.values(ExpertOrderType)).label(t('fields.orderType.label')),
    orderDirection: yup.mixed().oneOf(Object.values(ExpertOrderDirection)).label(t('fields.orderDirection.label')),
  });
