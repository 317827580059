import { useTheme } from 'styled-components';

import { ApexOptions } from 'apexcharts';
import { memo, useMemo } from 'react';
import Chart from 'react-apexcharts';
import { renderToString } from 'react-dom/server';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';

import { useGetSelectedBroker } from '@root/modules/orders/contexts/selected-broker.context';
import { usePerformanceChart } from '@root/modules/orders/hooks/use-performance-chart';
import { ChartType, customLegendCheckboxOff, customLegendCheckboxOn } from '@root/shared/constants/chart';
import { DateInput, Select } from '@root/shared/ui/form';
import { Switch, SwitchItem } from '@root/shared/ui/switch';
import { Period } from '@root/modules/orders/enums/orders';
import clsx from 'clsx';

const NOTION_LINK = 'https://www.notion.so/sagemaster/Growth-eae8c2f0e24f480fbf6147884f85446c';

export const PerformanceChart = memo(() => {
  const { t: tExperts } = useTranslation('forex-experts');

  const theme = useTheme();
  const { selectedBroker } = useGetSelectedBroker();

  const [{ isLoading, chartType, series, periodOptions, selectedPeriod, fromDate, toDate, dataLength }, { setPeriod, setChartType, setFromDate, setToDate }] = usePerformanceChart({ selectedBroker });

  const options = useMemo<ApexOptions>(
    () => ({
      zoom: {
        enabled: true,
      },
      xaxis: {
        labels: {
          rotate: -45,
          rotateAlways: [Period.Week, Period.Day].includes(selectedPeriod?.value as Period),
        },
        tickPlacement: 'on',
      },
      noData: {
        text: 'No data found',
        align: 'center',
        verticalAlign: 'middle',
        offsetY: -20,
        style: {
          // color: string
          fontSize: '16px',
        },
      },
      theme: {
        mode: 'dark',
      },
      dataLabels: {
        enabled: false,
        enabledOnSeries: [1],
      },
      grid: {
        borderColor: theme.gray['700'],
      },
      chart: {
        background: 'transparent',
        type: 'line',
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      stroke: {
        width: [0, 4, 4],
        curve: 'straight',
      },
      markers: {
        size: 4,
        strokeWidth: 0,
      },
      tooltip: {
        custom: ({ seriesIndex, w, dataPointIndex }) => {
          const series = w.config.series[seriesIndex];
          const hoveredData = series.data[dataPointIndex];
          const currency = series.name === 'Pips' ? series.name : '';

          return renderToString(
            <div className='p-2 bg-grayscale-800'>
              <p className='text-sm'>
                <div className='inline-block mr-1 w-2 h-2 rounded-full' style={{ background: hoveredData.fillColor }} />
                {series.name}
              </p>
              <p className='text-sm'>
                {hoveredData.y} {currency}
              </p>
              {/*<p className='text-sm'>Equity Growth: {equity} Pips</p>*/}
              {/*<p className='text-sm'>Overall Growth: {overall} Pips</p>*/}
              <p className='text-gray-600'>{hoveredData.x}</p>
            </div>,
          );
        },
      },
      legend: {
        position: 'bottom',
        itemMargin: {
          vertical: 0,
        },
        markers: {
          width: 0,
        },
        formatter: (val, opt) => {
          const visible = opt.w.config.series[opt.seriesIndex].data.length;
          const checkbox = visible ? customLegendCheckboxOn : customLegendCheckboxOff;
          if (opt.seriesIndex === 1) {
            return `<div style="display:flex; align-items: center; gap: 10px;">
              ${checkbox}
              ${val}
              ${chartType === ChartType.Growth ? `<a href="${NOTION_LINK}" class="font-medium text-primary-400 hover:text-gray-100 cursor-pointer !pointer-events-auto" target="_blank" rel="noreferrer">More details here</a>` : ''}
            </div>`;
          } else {
            return `<div style="display:flex; align-items: center; "> ${checkbox} ${val}</div>`;
          }
        },
      },
    }),
    [selectedPeriod?.value, theme.gray, chartType],
  );

  if (isLoading) {
    return <Skeleton height='100%' className='p-4' />;
  }

  return (
    <div className='bg-gray-800 px-5 pt-4 pb-0 rounded'>
      <div className='flex flex-wrap gap-2 items-center justify-between relative z-10'>
        <Switch>
          <SwitchItem active={chartType === ChartType.Growth} onClick={() => setChartType(ChartType.Growth)}>
            {tExperts('growthTab')}
          </SwitchItem>
          <SwitchItem active={chartType === ChartType.Profit} onClick={() => setChartType(ChartType.Profit)}>
            {tExperts('profitTab')}
          </SwitchItem>
        </Switch>
        <div className='flex flex-wrap justify-end items-center gap-2'>
          <DateInput
            size='small'
            value={[fromDate || null, toDate || null]}
            onChange={(value) => {
              if (value instanceof Array) {
                const [from, to] = value;
                setFromDate(from || null);
                setToDate(to || null);
              }
            }}
            monthsShown={2}
            className='!py-[3px]'
            maxDate={new Date()}
            withRange
            outlined
          />
          <Select tiny value={selectedPeriod} onChange={(option) => setPeriod(option.value)} options={periodOptions} alignMenuToRight bordered />
        </div>
      </div>
      <div className={clsx({ 'overflow-x-auto overflow-y-hidden': dataLength >= 30 })}>
        <Chart options={options} series={series} height={263.5} width={dataLength >= 30 ? dataLength * 30 : undefined} />
      </div>
    </div>
  );
});

PerformanceChart.displayName = 'PerformanceChart';
