import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { IExpert } from '@root/modules/experts/types/expert';
import { selectQuantityOptions } from '@root/shared/constants/items-per-page-options';
import { usePopover } from '@root/shared/hooks/use-popover';

import { EmptyData } from '@root/shared/ui/empty-data';
import { Select } from '@root/shared/ui/form';
import { Paginate } from '@root/shared/ui/pagination';
import { SkeletonTable } from '@root/shared/ui/skeleton-table/skeleton-table';
import { Table } from '@root/shared/ui/table';
import { TabType } from '../enums/orders';
import { GetOrderServiceQuery } from '../services/get-orders-history.service';
import { IOrder } from '../types/orders';
import { TradesTableRow } from '@root/modules/orders/components/trades-table-row';

const { Wrapper, Head, Row, HeadCellSlim, Body } = Table;

type Props = {
  tab: TabType;
  historyLoading: boolean;
  orders?: IOrder[];
  list: IOrder[];
  paginatedData: IOrder[];
  page: number;
  experts?: IExpert[];
  isExpertsDetails?: boolean;
  pageSizeInput: {
    [key: string]: any;
  };
  pagination?: {
    totalItems: number;
    totalPages: number;
    page: number;
    size: number;
  };
  deleteOrder: (order: IOrder) => void;
  modifyOrder: (order: IOrder) => void;
  modifyTrade: (order: IOrder) => void;
  modifyPartialClose: (order: IOrder) => void;
  shareOrder: (order: IOrder) => void;
  changeParams: (param: Partial<GetOrderServiceQuery>) => void;
  setPage: (page: number) => void;
  currency?: string;
  actionColumnHeaderContent?: React.JSX.Element;
};

dayjs.extend(utc);

export const TradesTableContent: FC<Props> = ({
  tab,
  page,
  historyLoading,
  orders,
  isExpertsDetails,
  experts,
  list,
  pagination,
  paginatedData,
  pageSizeInput,
  modifyOrder,
  modifyTrade,
  modifyPartialClose,
  deleteOrder,
  shareOrder,
  changeParams,
  setPage,
  currency,
  actionColumnHeaderContent,
}) => {
  const { t } = useTranslation('dashboard', { keyPrefix: 'trades' });
  const popover = usePopover({ trigger: 'click' });
  const expertNames = useMemo(() => experts?.reduce((acc, expert) => ({ ...acc, [expert.id]: expert.name }), {}) || {}, [experts]);

  return (
    <div>
      <Wrapper>
        <colgroup>
          <col />
          <col />
          <col />
          <col />
          <col />
          <col />
          <col />
          <col />
          <col />
          <col style={{ width: 1 }} />
        </colgroup>
        <Head>
          <Row>
            <HeadCellSlim>{t('columns.id')}</HeadCellSlim>
            {!isExpertsDetails && <HeadCellSlim>{t('columns.eaName')}</HeadCellSlim>}
            <HeadCellSlim>{t('columns.pair')}</HeadCellSlim>
            <HeadCellSlim>{t('columns.date')}</HeadCellSlim>
            <HeadCellSlim>{t('columns.type')}</HeadCellSlim>
            <HeadCellSlim>{t('columns.lotSize')}</HeadCellSlim>
            <HeadCellSlim>{t('columns.source')}</HeadCellSlim>
            <HeadCellSlim>{t('columns.open')}</HeadCellSlim>
            <HeadCellSlim>{t(tab === 'history' ? 'columns.closed' : 'columns.current')}</HeadCellSlim>
            <HeadCellSlim>{t('columns.pnl')}</HeadCellSlim>
            <HeadCellSlim>{t('columns.status')}</HeadCellSlim>
            <HeadCellSlim>
              {tab === 'history' && actionColumnHeaderContent}
            </HeadCellSlim>
          </Row>
        </Head>
        <Body>
          {historyLoading ? (
            <SkeletonTable columns={12} rows={10} />
          ) : (
            orders?.map((order) => <TradesTableRow
              key={order.ticket}
              order={order}
              tab={tab}
              currency={currency}
              expertNames={expertNames}
              popover={popover}
              isExpertsDetails={isExpertsDetails}
              shareOrder={shareOrder}
              modifyOrder={modifyOrder}
              deleteOrder={deleteOrder}
              modifyTrade={modifyTrade}
              modifyPartialClose={modifyPartialClose}
            />)
          )}
        </Body>
      </Wrapper>
      {!orders?.length && !historyLoading && <EmptyData />}

      {tab !== TabType.HISTORY && !!paginatedData.length && (
        <div className='flex flex-wrap justify-center items-center mt-2.5 pb-6 relative'>
          <Paginate
            pageCount={Math.ceil(list.length / (pageSizeInput.value?.value || 10))}
            forcePage={page}
            onPageChange={({ selected }) => setPage(selected)}
            marginPagesDisplayed={1}
            disableInitialCallback
          />

          <div className='w-24 ml-auto md:absolute right-5'>
            <Select
              value={pageSizeInput.value}
              onFocus={pageSizeInput.onFocus}
              onChange={pageSizeInput.onChange}
              onBlur={pageSizeInput.onBlur}
              options={selectQuantityOptions}
              isSearchable={false}
              bordered
              tiny
            />
          </div>
        </div>
      )}

      {tab === TabType.HISTORY && (
        <div className='flex flex-wrap justify-center items-center mt-2.5 pb-6 relative'>
          <Paginate
            pageCount={pagination?.totalPages || 1}
            forcePage={(pagination?.page || 1) - 1}
            onPageChange={({ selected }) => changeParams({ page: (selected || 0) + 1 })}
            marginPagesDisplayed={1}
            disableInitialCallback
          />
          <div className='w-24 ml-auto md:absolute right-5'>
            <Select
              value={{ value: pagination?.size, label: pagination?.size }}
              options={selectQuantityOptions}
              onChange={(data) => changeParams({ size: data.value })}
              isSearchable={false}
              bordered
              tiny
            />
          </div>
        </div>
      )}
    </div>
  );
};
