import { Formik, FormikProps } from 'formik';
import React, { FC, memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useGhostLogin } from '@root/shared-files/modules/auth/hooks/use-ghost-login';
import { ExpertSymbolSelect } from '@root/modules/experts/components/expert-symbol-select';
import { EditCopiedExpertDto } from '@root/modules/experts/dtos/edit-copied-expert.dto';
import { useCreateAccountStep } from '@root/modules/experts/hooks/use-create-account-step';
import { useEditCopiedMasterExpert } from '@root/modules/experts/hooks/use-edit-copied-master-expert';
import { SelectField } from '@root/shared/form';
import { Button } from '@root/shared/ui/button';
import { ResponsiveAddButton } from '@root/shared/ui/button/responsiveAddButton';
import { Modal } from '@root/shared/ui/modal';
import { Title } from '@root/shared/ui/typography';

const FormComponent: FC<FormikProps<EditCopiedExpertDto> & { closeModal(): void }> = ({ validateForm, values, handleSubmit, closeModal, isSubmitting, isValid }) => {
  const { t } = useTranslation('forex-experts');
  const [{ symbolOptions, account, accountOptions, symbols, selectAllSymbols, removeSymbolByIndex, clearAllSymbols }, { onAccountCreate, onSymbolChange }] = useCreateAccountStep(values.account);

  const { hideActions } = useGhostLogin();

  useEffect(() => {
    setTimeout(validateForm);
  }, [values]);

  return (
    <Modal isOpen onCancel={closeModal} footer={null} className=''>
      <form className='w-full' onSubmit={handleSubmit}>
        <div className='flex flex-col gap-3'>
          <Title level={6} className='font-bold text-center'>
            {t('actions.selectAccountAndRun')}
          </Title>

          <div className='flex items-start gap-x-4'>
            <div className='flex-1 w-full md:max-w-[280px]'>
              <SelectField
                options={accountOptions}
                isDisabled={!accountOptions?.length}
                name='account'
                wrapperClassName='mb-6'
                label={t('fields.account.label')}
                placeholder={t('fields.account.placeholder')}
              />
            </div>
            <ResponsiveAddButton onClick={onAccountCreate}>{t('actions.connectAccount')}</ResponsiveAddButton>
          </div>

          <ExpertSymbolSelect
            symbols={symbols}
            selectAllSymbols={selectAllSymbols}
            removeSymbolByIndex={removeSymbolByIndex}
            clearAllSymbols={clearAllSymbols}
            onSymbolChange={onSymbolChange}
            symbolOptions={symbolOptions}
            isDisabled={!account}
          />

          <div className='w-full flex justify-between items-center'>
            <Button outlined onClick={closeModal}>
              {t('close', { ns: 'common' })}
            </Button>
            <Button type='submit' loading={isSubmitting} disabled={!isValid || hideActions}>
              {t('actions.editAndRun')}
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export const EditCopiedMasterExpert = memo(() => {
  const { initialValues, onSubmit, validationSchema, closeModal, expert } = useEditCopiedMasterExpert();

  if (!initialValues || !expert) {
    return null;
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      validateOnChange={true}
      validateOnBlur={true}
      component={(props) => <FormComponent {...props} closeModal={closeModal} />}
      enableReinitialize
    />
  );
});


EditCopiedMasterExpert.displayName = 'EditCopiedMasterExpert';
