import clsx from 'clsx';
import { Formik, FormikProps } from 'formik';
import { FC, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Trans } from 'react-i18next';

import { SelectField } from '@root/shared/form';
import { CopyIcon } from '@root/shared/icons/copy-icon';
import { CopyWrapper } from '@root/shared/ui/copy-wrapper/copy-wrapper';
import { Label } from '@root/shared/ui/form';
import { FieldWrapper } from '@root/shared/ui/form/field/field-wrapper';
import { Text, Title } from '@root/shared/ui/typography';

import { HOW_IT_WORKS_LINK } from '../../constants/alert-links';
import { useAlertFields } from '../../hooks/use-alert-fields';
import { useAlertForm } from '../../hooks/use-alert-form';
import { Alert } from '../../types/alert-form';
import { IExpert } from '../../types/expert';

type Props = {
  expert: IExpert;
};

type AlertFormProps = FormikProps<Alert> & {
  url: string;
  expertId: string;
};

export const AlertsForm: FC<AlertFormProps> = ({ expertId, url, handleSubmit }) => {
  const ref = useRef<HTMLDivElement>(null);
  const { t } = useTranslation('forex-experts');
  const [{ generatedData, nameDescriptionKey, alertNameOptions, symbolOptions, isLoading, isSymbolAvailable }, { copyElement }] = useAlertFields(expertId);

  const jsonText = useMemo(() => (Object.keys(generatedData || {}).length ? JSON.stringify(generatedData, null, 2) : ''), [generatedData]);

  const prettyJson = useMemo(
    () =>
      `{<br /> <p class="pl-4">${Object.keys(generatedData)
        .map((key) => `<span>&quot;${key}&quot;: &quot;${generatedData[key]}&quot;</span>`)
        .join(',<br/>')}<br/></p>}`,
    [generatedData],
  );

  return (
    <form className='w-full' onSubmit={handleSubmit}>
      {url && (
        <div className='flex items-center gap-x-3 cursor-pointer mb-2' onClick={() => copyElement(url)}>
          <Text size='sm' className='text-success-400'>
            {url}
          </Text>
          <CopyWrapper onCopy={() => copyElement(url)}>
            <CopyIcon />
          </CopyWrapper>
        </div>
      )}
      <div className='grid md:grid-cols-2 gap-8'>
        <div>
          <Label>{t('alerts.fields.name.label')}</Label>
          <Text size='sm' className='text-grayscale-400'>
            {t('alerts.fields.name.hint')}
          </Text>
          <SelectField name='type' label={null} placeholder={t('alerts.fields.name.placeholder') as string} options={alertNameOptions} />
          <Text size='sm' className='text-grayscale-400 mt-3'>
            <Trans i18nKey={`forex-experts:${nameDescriptionKey}`} components={{ i: <i /> }} />
          </Text>
        </div>
        <div ref={ref}>
          {isSymbolAvailable && (
            <FieldWrapper name='symbol' variant='danger' className='-mb-5' showIfTouched={true}>
              <Text size='sm' className={clsx('mb-1', { 'text-grayscale-600 cursor-not-allowed': false })}>
                {t('alerts.fields.pair.label')}
              </Text>
              <Text size='sm' className={clsx('text-grayscale-400', { 'text-grayscale-600 cursor-not-allowed': false })}>
                {t('alerts.fields.pair.hint')}
              </Text>
              <div className='flex-1 w-full'>
                <SelectField
                  options={symbolOptions}
                  isDisabled={isLoading || !symbolOptions.length}
                  name='symbol'
                  wrapperClassName='mb-6'
                  placeholder={t('alerts.fields.pair.placeholder')}
                />
              </div>
            </FieldWrapper>
          )}
        </div>
      </div>
      <div className={clsx('grid md:grid-cols-2 gap-8', { 'mt-5': !!jsonText })}>
        {!!jsonText && (
          <div
            className='w-full flex justify-start items-start gap-x-5 cursor-pointer bg-grayscale-100 bg-opacity-10 p-5 rounded overflow-hidden'
            onClick={() => copyElement(jsonText)}
          >
            <Text size='sm' className='text-success-400 flex-grow overflow-hidden text-ellipsis' dangerouslySetInnerHTML={{ __html: prettyJson }} />
            <CopyWrapper onCopy={() => copyElement(jsonText)}>
              <div className='rounded bg-success-400 bg-opacity-10 p-2'>
                <CopyIcon className='flex-shrink-0 text-success-400' />
              </div>
            </CopyWrapper>
          </div>
        )}
      </div>
    </form>
  );
};

export const Alerts: FC<Props> = ({ expert }) => {
  const { t } = useTranslation('forex-experts');

  const [{ initialValues, url, schema }] = useAlertForm(expert);

  return (
    <div className='w-full'>
      <div className='p-5 bg-gray-800 rounded'>
        <div className='w-full flex justify-start items-center gap-x-3 mb-2'>
          <Title className='font-semibold' level={7}>
            {t('alerts.createAlert')}
          </Title>
          <a href={HOW_IT_WORKS_LINK} target='_blank' rel='noreferrer'>
            <Text size='sm' className='text-primary-400 underline cursor-pointer'>
              {t('alerts.howItWorks')}
            </Text>
          </a>
        </div>
        <Formik initialValues={initialValues} onSubmit={() => {}} validationSchema={schema} enableReinitialize validateOnChange>
          {(props) => <AlertsForm {...props} url={url} expertId={expert.id} />}
        </Formik>
      </div>
    </div>
  );
};
