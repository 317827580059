import { ProviderAnalytics } from '@root/modules/marketplace/containers/provider-analytics';
import { ProviderData } from '@root/modules/marketplace/containers/provider-data';
import { ProviderDisclaimer } from '@root/modules/marketplace/containers/provider-disclaimer';
import { ProviderInfo } from '@root/modules/marketplace/containers/provider-info';
import { Fragment, useEffect } from 'react';
import { useGetMarketplaceTradeIdeaProvider } from '../hooks/use-get-marketplace-trade-idea-provider';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { authSelector } from '@root/shared-files/modules/auth/store';
import { useNavigate } from 'react-router';
import { TIProviderStatus } from '@3lgn/shared/dist/constants/trade_ideas';
import { notify } from '@root/shared/utils/notification';
import { ProviderEventLogs } from '@root/modules/marketplace/containers/provider-event-logs';

export const ProviderContainer = ({ id }: { id: string }) => {
  const { data, isFetching: isLoading } = useGetMarketplaceTradeIdeaProvider(id, { shouldRedirect: true });
  const { t } = useTranslation('marketplace-trade-idea-provider-details');
  const user = useSelector(authSelector.user);
  const navigate = useNavigate();

  useEffect(() => {
    if (data) {
      if ([TIProviderStatus.UNPUBLISHED, TIProviderStatus.DRAFT].includes(data.status as TIProviderStatus)) {
        if (data.ownerIds?.includes(user?.id as string)) {
          return;
        }

        notify({
          title: t('notifications.unpublished'),
          type: 'danger',
        });
        navigate('/marketplace');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <Fragment>
      <ProviderInfo data={data} isLoading={isLoading} />
      <ProviderAnalytics data={data} isLoading={isLoading} />
      <ProviderData data={data} isLoading={isLoading} />
      <ProviderDisclaimer data={data} isLoading={isLoading} />
      <ProviderEventLogs data={data} isLoading={isLoading} />
    </Fragment>
  );
};
