import { useFormikContext } from 'formik';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { AdvancedSettings as AdvancedSettingsFields } from '@root/modules/experts/components/advanced-settings';
import { CreateExpertDto } from '@root/modules/experts/dtos/create-expert.dto';
import { CreateExpertStepKey } from '@root/modules/experts/hooks/use-create-expert-steps';
import { useExpertPartialEdit } from '@root/modules/experts/hooks/use-expert-partial-edit';
import { TemplateUnitType } from '@root/modules/experts/types/common';
import { IExpert } from '@root/modules/experts/types/expert';
import { Text } from '@root/shared/ui/typography';

import { EditableSettingsTitle } from './editable-settings-title';
import { useCreateExpertFieldOptions } from '@root/modules/experts/hooks/use-create-expert-options';

type Props = {
  expert: IExpert;
  editable?: boolean;
};

export const AdvancedSettings: FC<Props> = ({ editable = true }) => {
  const { t } = useTranslation('forex-experts');
  const { values, setFieldValue } = useFormikContext<CreateExpertDto>();
  const { editing, loading, saveSettings, cancelChanges, setEditing } = useExpertPartialEdit(CreateExpertStepKey.AdvancedSettings);

  const options = useCreateExpertFieldOptions(values?.unitType);

  const priceToleranceTypeOption = options.priceToleranceUnitTypeOptions.find((option) => option.value === values.strategy.priceToleranceType);
  const expiryTimeTypeOption = options.expiryTimeTypeOptions.find((option) => option.value === values.strategy.timeToleranceType);
  const tpProximityTypeOption = options.tpProximityOptions.find((option) => option.value === values.strategy.tpProximityType);

  useEffect(() => {
    setFieldValue('unitType', values.usePips ? TemplateUnitType.PIPS : TemplateUnitType.POINTS);
  }, [values.usePips, setFieldValue]);

  return (
    <div>
      <EditableSettingsTitle
        editDisabled={false}
        title={t('steps.advanced.title')}
        loading={loading}
        editing={editing}
        setEditing={setEditing}
        saveSettings={saveSettings}
        cancelChanges={cancelChanges}
        section={CreateExpertStepKey.AdvancedSettings}
        editable={editable}
      />
      {editing ? (
        <div className='w-full px-4 py-3 rounded bg-grayscale-700'>
          <AdvancedSettingsFields isPartialEdit />
        </div>
      ) : (
        <div className='w-full px-4 py-3 rounded flex flex-wrap justify-start items-center gap-1 bg-grayscale-700'>
          <div className='w-full flex justify-between flex-wrap gap-x-4 items-center'>
            <Text size='base' className='text-gray-500 flex-shrink-0'>
              {t('fields.slippage.use')}
            </Text>
            <Text size='base' className='text-right'>
              {values.useSlippage ? t('on') : t('off')}
            </Text>
          </div>
          {values.useSlippage && (
            <div className='w-full flex justify-between flex-wrap gap-x-4 items-center'>
              <Text size='base' className='text-gray-500 flex-shrink-0'>
                {t('fields.slippage.label')}
              </Text>
              <Text size='base' className='text-right'>
                {values.slippage}
              </Text>
            </div>
          )}
          <div className='w-full flex justify-between flex-wrap gap-x-4 items-center'>
            <Text size='base' className='text-gray-500 flex-shrink-0'>
              {t('fields.priceTolerance.label')}
            </Text>
            <Text size='base' className='text-right flex-grow'>
              {values.strategy.usePriceTolerance ? `${values.strategy.priceTolerance} ${priceToleranceTypeOption?.label}` : t('off')}
            </Text>
          </div>
          {
            values.strategy.usePriceTolerance && (
              <div className='w-full flex justify-between flex-wrap gap-x-4 items-center'>
                <Text size='base' className='text-gray-500 flex-shrink-0'>
                  {t('fields.expiryTime.label')}
                </Text>
                <Text size='base' className='text-right flex-grow'>
                  {values.strategy.timeTolerance ? `${values.strategy.timeTolerance} ${expiryTimeTypeOption?.label}` : t('off')}
                </Text>
              </div>
            )
          }
          <div className='w-full flex justify-between flex-wrap gap-x-4 items-center'>
            <Text size='base' className='text-gray-500 flex-shrink-0'>
              {t('fields.postSignalValidation.label')}
            </Text>
            <Text size='base' className='text-right flex-grow'>
              {values.strategy.usePostSignalProximity ? t('on') : t('off')}
            </Text>
          </div>
          {
            values.strategy.usePostSignalProximity && (
              <div className='w-full flex justify-between flex-wrap gap-x-4 items-center'>
                <Text size='base' className='text-gray-500 flex-shrink-0'>
                  {t('fields.tpProximity.label')}
                </Text>
                <Text size='base' className='text-right flex-grow'>
                  {values.strategy.tpProximity} {tpProximityTypeOption?.label}
                </Text>
              </div>
            )
          }
        </div>
      )}
    </div>
  );
};
