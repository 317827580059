import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ExpertOrderDirection, ExpertOrderType, TemplateUnitType } from '@root/modules/experts/types/common';
import { FxSessionManagementActionType, FxTradingSession } from '@3lgn/shared/dist/constants/fx';


export const useCreateExpertFieldOptions = (unitType: TemplateUnitType = TemplateUnitType.PIPS) => {
  const { t } = useTranslation('forex-experts', { keyPrefix: 'fields' });

  const strategyTypeOptions = useMemo(() => [
    {
      value: 'signal',
      label: t('strategyTypeWebhook.options.0'),
    },
    {
      value: 'manual',
      label: t('strategyTypeWebhook.options.1'),
    },
  ], [t])

  const manualMoneyManagementType = useMemo(
    () => [
      {
        value: '0',
        label: t('manualMoneyManagement.type.options.0'),
      },
      {
        value: '1',
        label: t('manualMoneyManagement.type.options.1'),
      },
      // {
      //   value: '2',
      //   label: t('manualMoneyManagement.type.options.2'),
      // },
    ],
    [t],
  );

  const manualMoneyManagementBasedOn = useMemo(
    () => [
      {
        value: '0',
        label: t('manualMoneyManagement.basedOn.options.0'),
      },
      {
        value: '1',
        label: t('manualMoneyManagement.basedOn.options.1'),
      },
      {
        value: '2',
        label: t('manualMoneyManagement.basedOn.options.2'),
      },
    ],
    [t],
  );

  const signalMoneyManagementType = useMemo(
    () => [
      {
        value: '0',
        label: t('signalMoneyManagement.type.options.0'),
      },
      {
        value: '1',
        label: t('signalMoneyManagement.type.options.1'),
      },
      {
        value: '2',
        label: t('signalMoneyManagement.type.options.2'),
      },
      {
        value: '3',
        label: t('signalMoneyManagement.type.options.3'),
      },
      {
        value: '4',
        label: t('signalMoneyManagement.type.options.4'),
      },
    ],
    [t],
  );

  const signalMoneyManagementBasedOn = useMemo(
    () => [
      {
        value: '0',
        label: t('signalMoneyManagement.basedOn.options.0'),
      },
      {
        value: '1',
        label: t('signalMoneyManagement.basedOn.options.1'),
      },
      {
        value: '2',
        label: t('signalMoneyManagement.basedOn.options.2'),
      },
    ],
    [t],
  );

  const manualStopLossTakeProfitType = useMemo(
    () => [
      {
        value: '0',
        label: t('manualStopLossTakeProfit.type.options.0'),
      },
      {
        value: '1',
        label: t('manualStopLossTakeProfit.type.options.1'),
      },
    ],
    [t],
  );

  const signalStopLossTakeProfit = useMemo(
    () => [
      {
        value: '0',
        label: t('signalStopLossTakeProfit.type.options.0'),
      },
      {
        value: '1',
        label: t('signalStopLossTakeProfit.type.options.1'),
      },
      {
        value: '2',
        label: t('signalStopLossTakeProfit.type.options.2'),
      },
      {
        value: '3',
        label: t('signalStopLossTakeProfit.type.options.3'),
      },
      {
        value: '4',
        label: t('signalStopLossTakeProfit.type.options.4'),
      },
    ],
    [t],
  );

  const riskPercentages = useMemo(
    () => [
      {
        value: '0.5',
        label: '0.5%',
      },
      {
        value: '1',
        label: '1%',
      },
      {
        value: '2',
        label: '2%',
      },
      {
        value: '3',
        label: '3%',
      },
      {
        value: '4',
        label: '4%',
      },
      {
        value: '5',
        label: '5%',
      },
    ],
    [],
  );

  const smartEntryProfitCalculationType = useMemo(
    () => [
      {
        label: t('signalStopLossTakeProfit.profitCalculationType.options.0'),
        value: '0',
      },
      {
        label: t('signalStopLossTakeProfit.profitCalculationType.options.1'),
        value: '1',
      },
    ],
    [t],
  );

  const rrRatioOptions = useMemo(
    () => [
      {
        label: t('signalStopLossTakeProfit.rrRatio.options.0'),
        value: '0',
      },
      {
        label: t('signalStopLossTakeProfit.rrRatio.options.1'),
        value: '1',
      },
      {
        label: t('signalStopLossTakeProfit.rrRatio.options.2'),
        value: '2',
      },
      {
        label: t('signalStopLossTakeProfit.rrRatio.options.3'),
        value: '3',
      },
      {
        label: t('signalStopLossTakeProfit.rrRatio.options.4'),
        value: '4',
      },
      {
        label: t('signalStopLossTakeProfit.rrRatio.options.5'),
        value: '5',
      },
    ],
    [t],
  );

  const partialCalculationOptions = useMemo(
    () => [
      {
        label: t('partialClose.calculationType.options.0'),
        value: '0',
      },
      {
        label: t('partialClose.calculationType.options.1'),
        value: '1',
      },
    ],
    [t],
  );

  const slTypeOptions = useMemo(
    () => [
      {
        label: t('signalStopLossTakeProfit.slType.options.0'),
        value: '0',
      },
      {
        label: t('signalStopLossTakeProfit.slType.options.1', { unitType: unitType }),
        value: '1',
      },
    ],
    [t, unitType],
  );

  const breakEvenTypeOptions = useMemo(
    () => [
      {
        label: t('breakEven.breakEvenType.options.0', { unitType: unitType }),
        value: '0',
      },
      {
        label: t('breakEven.breakEvenType.options.1'),
        value: '1',
      },
    ],
    [t, unitType],
  );

  const priceToleranceUnitTypeOptions = useMemo(
    () => [
      {
        label: t('priceTolerance.unitOptions.0', { unitType }),
        value: '0',
      },
      {
        label: t('priceTolerance.unitOptions.1'),
        value: '1',
      },
    ],
    [t, unitType],
  );

  const expiryTimeTypeOptions = useMemo(
    () => [
      {
        label: t('expiryTime.unitOptions.0'),
        value: '0',
      },
      {
        label: t('expiryTime.unitOptions.1'),
        value: '1',
      },
    ],
    [t],
  );

  const tpProximityOptions = useMemo(
    () => [
      {
        label: t('tpProximity.unitOptions.0', { unitType }),
        value: '0',
      },
      {
        label: t('tpProximity.unitOptions.1'),
        value: '1',
      },
    ],
    [t, unitType],
  );

  const drawdownBalanceTypeOptions = useMemo(
    () => [
      {
        label: t('drawdown.balanceType.options.0'),
        value: '0',
      },
      {
        label: t('drawdown.balanceType.options.1'),
        value: '1',
      },
      {
        label: t('drawdown.balanceType.options.2'),
        value: '2',
      },
    ],
    [t],
  );

  const drawdownLimitPeriodOptions = useMemo(
    () => [
      {
        label: t('drawdown.drawdownLimitPeriod.options.0'),
        value: '0',
      },
      {
        label: t('drawdown.drawdownLimitPeriod.options.1'),
        value: '1',
      },
      {
        label: t('drawdown.drawdownLimitPeriod.options.2'),
        value: '2',
      },
    ],
    [t],
  );

  const sessionManagementTypeOptions = useMemo(
    () => [
      {
        label: t('sessionManagement.type.optionsAlt.0.label'),
        description: t('sessionManagement.type.optionsAlt.0.hint'),
        value: FxSessionManagementActionType.MOVE_SL_TO_BREAKEVEN,
      },
      {
        label: t('sessionManagement.type.optionsAlt.1.label', { unitType }),
        description: t('sessionManagement.type.optionsAlt.1.hint'),
        value: FxSessionManagementActionType.CLOSE_IF_IN_PROFIT,
      },
      {
        label: t('sessionManagement.type.optionsAlt.2.label'),
        value: FxSessionManagementActionType.LEAVE_RUNNING,
      },
    ],
    [t],
  );

  const sessionManagementSessionOptions = useMemo(
    () => [
      {
        label: t('sessionManagement.sessions.options.all.label'),
        description: '',
        value: 'all',
      },
      {
        label: t('sessionManagement.sessions.options.sydney.label'),
        description: t('sessionManagement.sessions.options.sydney.description'),
        value: FxTradingSession.SYDNEY,
      },
      {
        label: t('sessionManagement.sessions.options.tokyo.label'),
        description: t('sessionManagement.sessions.options.tokyo.description'),
        value: FxTradingSession.TOKYO,
      },
      {
        label: t('sessionManagement.sessions.options.london.label'),
        description: t('sessionManagement.sessions.options.london.description'),
        value: FxTradingSession.LONDON,
      },
      {
        label: t('sessionManagement.sessions.options.newYork.label'),
        description: t('sessionManagement.sessions.options.newYork.description'),
        value: FxTradingSession.NEW_YORK,
      },
    ],
    [t],
  );

  const orderTypeOptions = useMemo(
    () => [
      {
        value: ExpertOrderType.NEUTRAL,
        label: t('orderType.options.neutral'),
      },
      {
        value: ExpertOrderType.MARKET,
        label: t('orderType.options.market'),
      },
      {
        value: ExpertOrderType.LIMIT,
        label: t('orderType.options.limit'),
      },
    ],
    [t],
  );

  const orderDirectionOptions = useMemo(
    () => [
      {
        value: ExpertOrderDirection.NEUTRAL,
        label: t('orderDirection.options.neutral'),
      },
      {
        value: ExpertOrderDirection.BUY,
        label: t('orderDirection.options.buy'),
      },
      {
        value: ExpertOrderDirection.SELL,
        label: t('orderDirection.options.sell'),
      },
    ],
    [t],
  );

  return {
    strategyTypeOptions,
    manualMoneyManagementType,
    manualMoneyManagementBasedOn,
    signalMoneyManagementType,
    signalMoneyManagementBasedOn,
    manualStopLossTakeProfitType,
    signalStopLossTakeProfit,
    riskPercentages,
    rrRatioOptions,
    partialCalculationOptions,
    drawdownBalanceTypeOptions,
    drawdownLimitPeriodOptions,
    sessionManagementTypeOptions,
    sessionManagementSessionOptions,
    smartEntryProfitCalculationType,
    slTypeOptions,
    breakEvenTypeOptions,
    priceToleranceUnitTypeOptions,
    expiryTimeTypeOptions,
    tpProximityOptions,
    orderTypeOptions,
    orderDirectionOptions,
  };
};
