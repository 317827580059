import { useTranslation } from 'react-i18next';

import { Layout } from '@root/infra/layout/containers';
import { BrokerAccountSelect } from '@root/modules/accounts/components/broker-account-select';
import { useGetAccountCurrency } from '@root/modules/accounts/hooks/use-get-account-currency';
import { PerformanceChart } from '@root/modules/dashboard/containers/performance-chart';
import { CreateExpertDropdown } from '@root/modules/experts/components/create-expert-dropdown';
import { FundsInfo } from '@root/modules/experts/components/funds-info';
import { ProfitInfo } from '@root/modules/experts/components/profit-info';
import { EditCopiedMasterExpert } from '@root/modules/experts/containers/edit-copied-master-expert';
import { useGetSelectedBroker } from '@root/modules/orders/contexts/selected-broker.context';
import { useGetOrdersProfit } from '@root/modules/orders/hooks/use-get-orders-profit';
import { SkeletonLoader } from '@root/shared/ui/skeleton-loader/skeleton-loader';
import { Title } from '@root/shared/ui/typography';

import { ExpertAssists } from '../components/experts-assists';
import { NoExpertBanner } from '../components/no-expert-banner';
import { useGetExperts } from '../hooks/use-get-experts';

const ExpertsPage = () => {
  const { t } = useTranslation('forex-experts');
  const { data, isLoading } = useGetExperts();
  const { selectedBroker } = useGetSelectedBroker();
  const { currency } = useGetAccountCurrency(selectedBroker);

  const { todayPnl, totalOrdersData, openOrdersData } = useGetOrdersProfit({
    selectedBroker,
  });

  return (
    <Layout>
      <div>
        {data?.length ? (
          <>
            <div className='flex mb-4 flex-wrap align-start md:flex-row md:justify-between md:w-auto gap-4'>
              <div className='flex items-center order-1 gap-4'>
                <Title level={5} bold>
                  {t('list')}
                </Title>
              </div>

              <div className='order-2 min-[880px]:order-3 w-fit ml-auto flex items-center gap-2 flex-wrap md:ml-auto lg:ml-0'>
                <BrokerAccountSelect />
                <CreateExpertDropdown buttonClassName='!py-1.5' buttonText={t('actions.create')} />
              </div>
            </div>

            <div className='grid grid-cols-12 gap-3 mb-4'>
              <div className='col-span-12 lg:col-span-4 flex flex-col gap-y-3 '>
                <ProfitInfo isLoading={isLoading} today={todayPnl} total={totalOrdersData} currency={currency} />
                <FundsInfo openOrdersProfit={openOrdersData.profit} openOrdersPips={openOrdersData.pips} currency={currency} />
              </div>
              <div className='col-span-12 lg:col-span-8'>
                <PerformanceChart />
              </div>
            </div>
          </>
        ) : (
          <div className='flex items-center order-1 gap-4'>
            <Title level={5} bold>
              {t('list')}
            </Title>
          </div>
        )}

        {isLoading ? (
          <div className='grid gap-6 grid-cols-1 lg:grid-cols-3'>
            <SkeletonLoader
              shapes={[
                { circle: false, width: '100%', height: 169 },
                { circle: false, width: '100%', height: 169 },
                { circle: false, width: '100%', height: 169 },
                { circle: false, width: '100%', height: 169 },
              ]}
            />
          </div>
        ) : data?.length ? (
          <ExpertAssists />
        ) : (
          <NoExpertBanner />
        )}
      </div>
      <EditCopiedMasterExpert />
    </Layout>
  );
};

export default ExpertsPage;
