import { useFormikContext } from 'formik';
import { useCallback } from 'react';

import { CreateExpertDto } from '../dtos/create-expert.dto';

export const useSignalTakeProfitFields = (isPartialEdit = false) => {
  const { values, setFieldValue } = useFormikContext<CreateExpertDto>();

  const fillRRDefaultProfits = useCallback(
    (rr?: number) => {
      const { signalSlTp } = values;
      const rrRatio = rr || Number(signalSlTp.rrRatio) + 1;
      const takeprofits = Array.from({ length: rrRatio }).map((_, index) => ({ amount: `${index + 1}`, percent: '' }));
      setFieldValue('partialClose.use', true);
      setFieldValue('partialClose.calculationType', '1');
      setFieldValue('partialClose.takeprofits', takeprofits);
    },
    [values, setFieldValue],
  );

  const onSlTpTypeChange = useCallback(
    (option) => {
      setFieldValue('signalSlTp.type', option.value);

      if (option.value === '4') {
        setFieldValue('signalSlTp.profitCalculationType', '0');
        setFieldValue('signalSlTp.rrRatio', '1');
      } else {
        setFieldValue('signalSlTp.profitCalculationType', '0');
        setFieldValue('signalSlTp.fixedSl', '');
        setFieldValue('signalSlTp.fixedTp', '');
        setFieldValue('signalSlTp.tpRatio', '');
      }

      if (!isPartialEdit) {
        if (option.value === '4') {
          fillRRDefaultProfits(2);
        } else {
          setFieldValue('partialClose.calculationType', '0');
          setFieldValue('partialClose.takeprofits', [
            {
              amount: '',
              percent: '',
            }
          ]);
        }
      }
    },
    [isPartialEdit, setFieldValue, fillRRDefaultProfits],
  );

  const onCalculationTypeChange = useCallback(
    (option) => {
      setFieldValue('signalSlTp.profitCalculationType', option.value);
      if (option.value === '0') {
        setFieldValue('signalSlTp.rrRatio', '1');
        if (!isPartialEdit) {
          fillRRDefaultProfits(2);
        }
      }
    },
    [isPartialEdit, fillRRDefaultProfits, setFieldValue],
  );

  const onRRRatioChange = useCallback(
    (option) => {
      setFieldValue('signalSlTp.rrRatio', option.value);
      if (!isPartialEdit) {
        const rr = Number(option.value) + 1;
        fillRRDefaultProfits(rr);
      }
    },
    [isPartialEdit, setFieldValue, fillRRDefaultProfits],
  );

  return {
    onSlTpTypeChange,
    onCalculationTypeChange,
    onRRRatioChange,
    fillRRDefaultProfits,
  };
};
