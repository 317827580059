import clsx from 'clsx';
import { FieldArray, useFormikContext } from 'formik';
import { FC, useCallback, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { CreateExpertDto } from '@root/modules/experts/dtos/create-expert.dto';
import { TemplateUnitType } from '@root/modules/experts/types/common';
import { SelectField, TextField } from '@root/shared/form';
import { SwitchField } from '@root/shared/form/fields/switch-field';
import { CloseMenuIcon } from '@root/shared/icons/close-menu-icon';
import { PlusIcon } from '@root/shared/icons/plus-icon';
import { Button } from '@root/shared/ui/button';
import { IconLabel } from '@root/shared/ui/form';
import { InfoTooltip } from '@root/shared/ui/info-tooltip';
import { Text, Title } from '@root/shared/ui/typography';
import { useCreateExpertFieldOptions } from '../hooks/use-create-expert-options';
import { makeExpertFormValues } from '../helpers/make-expert-form-values';
import { InfoTooltipIcon } from '@root/shared/icons/info-tooltip-icon';

export const PartialTakeProfits: FC<{ isPartialEdit?: boolean, isTradeUpdate?: boolean, initialValues?: CreateExpertDto['partialClose'] }> = ({ isPartialEdit, isTradeUpdate, initialValues }) => {
  const { t } = useTranslation('forex-experts', { keyPrefix: 'fields.partialClose' });

  const { values, errors, setFieldValue } = useFormikContext<CreateExpertDto>();

  const unitType = values.unitType || TemplateUnitType.POINTS;
  const use = values.partialClose?.use;
  const calculationType = values.partialClose?.calculationType;
  const takeprofits = values.partialClose?.takeprofits;

  const levelsErrors = errors.partialClose?.takeprofits && values.partialClose?.takeprofits?.length;
  const arrayError = typeof errors.partialClose?.takeprofits === 'string' && errors.partialClose?.takeprofits;

  const options = useCreateExpertFieldOptions();

  const partialCalculationOptions = useMemo(() => {
    const { partialCalculationOptions } = options;

    return [
      {
        value: partialCalculationOptions[0].value,
        label: partialCalculationOptions[0].label,
        isDisabled: false,
      },
      {
        value: partialCalculationOptions[1].value,
        label: partialCalculationOptions[1].label,
        isDisabled: false,
      },
    ]
  }, [options]);

  const selectedRROption = useMemo(() => options.rrRatioOptions.find((option) => option.value === values.signalSlTp.rrRatio), [options.rrRatioOptions, values.signalSlTp.rrRatio]);
  const rrProfitsLength = Number(selectedRROption?.value) + 1;
  const slTpType = values.signalSlTp?.type;
  const slTpProfitCalculationType = values.signalSlTp?.profitCalculationType;

  const onCalculateTypeChange = useCallback((option) => {
    setFieldValue('partialClose.calculationType', option.value);
    if (option.value === '0') {
      setFieldValue('partialClose.takeprofits', [{
        amount: '',
        percent: '',
      }]);
    } else {
      setFieldValue('partialClose.takeprofits', [{
        amount: '1',
        percent: '',
      }]);
    }
  }, [setFieldValue]);

  const onUseChange = useCallback((event) => {
    const checked = event.target.checked;
    const defaultValues = makeExpertFormValues();

    if (checked) {
      if (isTradeUpdate && initialValues) {
        if (!initialValues.use) {
          setFieldValue('partialClose', {
            takeprofits: [{
              amount: '',
              percent: '',
            }],
            calculationType: '0',
            use: true,
          });
          return;
        }

        setFieldValue('partialClose', {
          ...initialValues,
          use: true,
        });

        return;
      }
      setFieldValue('partialClose', defaultValues.partialClose);
    } else {
      setFieldValue('partialClose.use', false);
    }
  }, [initialValues, isTradeUpdate, setFieldValue]);

  return (
    <div>
      <SwitchField name='partialClose.use' label={<Title level={7}>{t('use.label')}</Title>} wrapperClassName='mb-2' onChange={onUseChange} />
      {
        !isTradeUpdate && (
          <Text size='sm' className='text-gray-500 mb-4'>
            {t('use.description')}
          </Text>
        )
      }
      {use && (
        <>
          <SelectField
            name='partialClose.calculationType'
            label={
              <IconLabel
                icon={
                  <InfoTooltip content={<Text size='sm'>{t('calculationType.description')}</Text>}>
                    <InfoTooltipIcon />
                  </InfoTooltip>
                }
              >
                {t('calculationType.label')}
              </IconLabel>
            }
            wrapperClassName='mb-4'
            placeholder={t('calculationType.placeholder')}
            options={partialCalculationOptions}
            onChange={onCalculateTypeChange}
            isDisabled={isTradeUpdate}
          />
          {
            calculationType === '0' && (
              <FieldArray name='partialClose.takeprofits'>
                {({ push, remove }) => (
                  <div>
                    <Text className='mb-2'>{t('levels.label')}</Text>
                    {takeprofits.map((_, index) => (
                      <div className='flex items-start' key={index}>
                        <div className={clsx({ 'flex-grow gap-x-2': isPartialEdit, 'flex-1 grid sm:grid-cols-2 gap-x-4': !isPartialEdit })}>
                          <TextField
                            name={`partialClose.takeprofits.${index}.amount`}
                            disabled={!use}
                            label={
                              index === 0 ? (
                                <IconLabel
                                  icon={
                                    <InfoTooltip content={<Text size='sm'>{t('levels.amount.description')}</Text>}>
                                      <InfoTooltipIcon />
                                    </InfoTooltip>
                                  }
                                >
                                  {t('levels.amount.label')}
                                </IconLabel>
                              ) : (
                                ' '
                              )
                            }
                            placeholder={t('levels.amount.placeholder')}
                            suffix={unitType}
                            suffixClassName='max-w-[30px] sm:max-w-none text-sm sm:text-[16px] truncate'
                          />
                          <TextField
                            name={`partialClose.takeprofits.${index}.percent`}
                            disabled={!use}
                            label={
                              index === 0 ? (
                                <IconLabel
                                  icon={
                                    <InfoTooltip content={<Text size='sm'>{t('levels.percent.description')}</Text>}>
                                      <InfoTooltipIcon />
                                    </InfoTooltip>
                                  }
                                >
                                  {t('levels.percent.label')}
                                </IconLabel>
                              ) : (
                                ' '
                              )
                            }
                            placeholder={t('levels.percent.placeholder')}
                            suffix='%'
                          />
                        </div>
                        <button
                          type='button'
                          onClick={() => remove(index)}
                          className={clsx('py-[15px] px-5 hover:opacity-80 bg-white bg-opacity-10 mt-[26px] ml-4 text-xs rounded', { 'cursor-not-allowed': index === 0 })}
                        >
                          <CloseMenuIcon />
                        </button>
                      </div>
                    ))}
                    {arrayError && (
                      <Text size='sm' className='text-danger-500 mb-4 mt-2 text-right'>
                        {arrayError}
                      </Text>
                    )}
                    <Button disabled={!!levelsErrors} outlined onClick={() => push({ afterX: '', setToY: '' })} className='mt-2 border-none !px-0' prefix={<PlusIcon />}>
                      {t('levels.add')}
                    </Button>
                  </div>
                )}
              </FieldArray>
            )
          }
          {
            calculationType === '1' && (
              <>
                <Text size='sm' className='text-grayscale-400 mb-4'>{t('calculationType.smartPartialProfitDescription')}</Text>
                {
                  slTpType === '4' && slTpProfitCalculationType === '0' && <Text size='sm' className='mb-4 text-grayscale-400'>
                    <Trans
                      i18nKey='forex-experts:fields.partialClose.smartLevels.label'
                      components={{ span: <span className='font-bold text-gray-100' /> }}
                      values={{ rr: selectedRROption?.label, tpLength: rrProfitsLength - 1 }}
                    />
                  </Text>
                }
                <FieldArray name='partialClose.takeprofits'>
                  {({ push, remove }) => (
                    <div>
                      {takeprofits.map((_, index) => (
                        <div className='flex items-start' key={index}>
                          <div className={clsx({ 'flex-grow gap-x-2': isPartialEdit, 'flex-1 flex justify-between items-center gap-x-4': !isPartialEdit })}>
                            <Text size='sm' className='mt-6 whitespace-nowrap min-w-[50px]'>TP {index + 1}</Text>
                            <TextField
                              name={`partialClose.takeprofits.${index}.percent`}
                              disabled={!use || isTradeUpdate}
                              label={
                                index === 0 ? (
                                  <IconLabel
                                    icon={
                                      <InfoTooltip content={<Text size='sm'>{t('smartLevels.percent.description')}</Text>}>
                                        <InfoTooltipIcon />
                                      </InfoTooltip>
                                    }
                                  >
                                    {t('smartLevels.percent.label')}
                                  </IconLabel>
                                ) : (
                                  ' '
                                )
                              }
                              placeholder={t('levels.percent.placeholder')}
                              suffix='%'
                              wrapperClassName='flex-grow'
                            />
                          </div>
                          <button
                            type='button'
                            disabled={isTradeUpdate}
                            onClick={() => remove(index)}
                            className={clsx('py-[15px] px-5 hover:opacity-80 bg-white bg-opacity-10 mt-[26px] ml-4 text-xs rounded disabled:opacity-40')}
                          >
                            <CloseMenuIcon />
                          </button>
                        </div>
                      ))}
                      {arrayError && (
                        <Text size='sm' className='text-danger-500 mb-4 mt-2 text-right'>
                          {arrayError}
                        </Text>
                      )}
                      <Button disabled={!!levelsErrors || isTradeUpdate} outlined onClick={() => push({ amount: (takeprofits.length + 1).toString(), percent: '' })} className='mt-2 border-none !px-0' prefix={<PlusIcon />}>
                        {t('levels.add')}
                      </Button>
                    </div>
                  )}
                </FieldArray>
              </>
            )
          }
        </>
      )}
    </div>
  );
};
