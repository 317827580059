import * as yup from 'yup';

import { FormikHelpers } from 'formik';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { queryClient } from '@root/infra/query';
import { ModifyModalProps } from '@root/modules/orders/components/update-order-modal';
import { IFormStatus } from '@root/shared/form';
import { notify } from '@root/shared/utils/notification';
import { Id } from '@root/shared/utils/types';

import { PartialCloseDto } from '../dtos/partical-close.dto';
import { modifyPartialCloseService } from '../services/modify-partial-close.service';

export interface ModifyPartialCloseDto extends PartialCloseDto {
  usePercentage: boolean;
  closeAbsoluteValue: number | string;
  closePercentValue: number | string;
}

export const useModifyPartialClose = ({ order, closeModal }: ModifyModalProps) => {
  const { t } = useTranslation('forex-orders');

  const location = useLocation();

  const expertId = location.pathname.replace('/assists/', '');

  const initialValues = useMemo<ModifyPartialCloseDto>(
    () => ({
      lots: `${order.lots || 0}`,
      symbol: order.symbol,
      ticket: order.ticket.toString(),
      usePercentage: false,
      closeAbsoluteValue: 0,
      closePercentValue: 0,
      expertId: (order.expertId as string) || '64a430aac025182a5a357618',
    }),
    [order.symbol, order.ticket, order.lots, order.expertId],
  );

  const onSubmit = useCallback(
    async (values: ModifyPartialCloseDto, helpers: FormikHelpers<ModifyPartialCloseDto>) => {
      helpers.setStatus(undefined);

      const result = await modifyPartialCloseService({
        lots: Number(values.closeAbsoluteValue),
        ticket: Number(values.ticket),
        symbol: values.symbol,
        expertId: values.expertId,
      });

      if (result.status === 200) {
        helpers.setStatus({ type: 'success', message: null } as IFormStatus);

        expertId ? queryClient.invalidateQueries(['fx', expertId]) : queryClient.invalidateQueries(['fx', order.accountId as Id, 'open-orders']);

        notify({
          type: 'success',
          title: t('updated'),
        });

        closeModal();
      } else {
        helpers.setStatus({ type: 'error', message: result.payload } as IFormStatus);
        notify({
          type: 'danger',
          title: result.payload,
        });
      }
    },
    [order, expertId, t, closeModal],
  );

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        lots: yup.number(),
        closeAbsoluteValue: yup
          .number()
          .typeError(t('number.type', { ns: 'yup' }))
          .required()
          .positive()
          .test('is-not-more-than', t('fields.lotSize.validation-max-size'), function (value) {
            const { lots } = this.parent;
            return `${value}` <= lots;
          })
          .test('has-two-decimals', t('fields.lotSize.validation-decimals'), (value) => value === undefined || /^\d+(\.\d{1,2})?$/.test(`${value}`))
          .label(t('fields.lotSize.label')),
        closePercentValue: yup
          .number()
          .typeError(t('number.type', { ns: 'yup' }))
          .required()
          .positive()
          .max(100, t('fields.lotSize.validation-max-percent'))
          .label(t('fields.lotSize.label')),
      }),
    [t],
  );

  return { initialValues, onSubmit, validationSchema };
};
