import { useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

import { authSelector } from '@root/shared-files/modules/auth/store';
import { GET_ORDERS_HISTORY } from '@root/modules/orders/queries/get-orders-history.query';
import { GetOrderServiceQuery } from '@root/modules/orders/services/get-orders-history.service';
import { Id } from '@root/shared/utils/types';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useGetAccounts } from '@root/modules/accounts/hooks/use-get-accounts';

dayjs.extend(utc);

type Props = {
  enabled: boolean;
  expertId?: string;
  mtType?: string;
  selectedBroker: string;
};

export const useGetOrdersHistory = ({ enabled, expertId, selectedBroker, mtType }: Props) => {
  const userId = useSelector(authSelector.userId);
  const { data: accounts } = useGetAccounts();

  const initialData = useMemo<GetOrderServiceQuery>(
    () => ({
      size: 10,
      orderDirection: 'DESC',
      orderBy: 'closeTime',
      page: 1,
      userId: userId as Id,
      accounts: selectedBroker,
      expertId,
    }),
    [selectedBroker, userId, expertId],
  );

  const [controlParams, setControlParam] = useState<GetOrderServiceQuery>(initialData);

  const { data, refetch, isLoading, isFetching } = useQuery(['fx', controlParams, 'orders-history'], GET_ORDERS_HISTORY, {
    enabled: enabled,
    keepPreviousData: false,
  });

  const orders = useMemo(() => {
    if (data?.orders) {
      return data.orders?.filter(order => {
        if (mtType) {
          const accountId = order.accountId;
          const account = accounts?.find(acc => acc.id === accountId);
          if (account) {
            return account.mtType === mtType;
          }
          return true;
        }

        return true;
      }).sort((a, b) => {
        const isAUtc = !!a.closeTime;
        const isBUtc = !!b.closeTime;
        const aTime = new Date((isAUtc ? dayjs.utc(a.closeTime) : dayjs(a.updatedAt)).local().toISOString()).getTime();
        const bTime = new Date((isBUtc ? dayjs.utc(b.closeTime) : dayjs(b.updatedAt)).local().toISOString()).getTime();

        return bTime - aTime;
      });
    }
  }, [data, accounts, mtType]);

  const changeParams = useCallback((param: Partial<GetOrderServiceQuery>) => {
    setControlParam((prev) => {
      if (param.size) {
        return {
          ...prev,
          ...param,
          page: 1,
        };
      }

      return {
        ...prev,
        ...param,
      };
    });
  }, []);

  useEffect(() => {
    if (controlParams.accounts !== selectedBroker) {
      changeParams({ accounts: selectedBroker });
    }
  }, [controlParams.accounts, selectedBroker, changeParams]);

  const state = {
    ordersHistoryList: orders,
    pagination: data?.pagination,
    controlParams,
    historyLoading: isLoading || isFetching,
  };

  const handlers = {
    changeParams,
    refetch,
  };

  return [state, handlers] as [typeof state, typeof handlers];
};
