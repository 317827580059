import { useCallback, useState } from 'react';
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

import { queryClient } from '@root/infra/query';
import { useGetAccounts } from '@root/modules/accounts/hooks/use-get-accounts';
import { useGetSymbols } from '@root/modules/accounts/hooks/use-get-symbols';
import { signInAccountService } from '@root/modules/accounts/services/sign-in-account.service';
import { authSelector } from '@root/shared-files/modules/auth/store';
import { useGetAllowedSymbols } from '@root/modules/experts/hooks/use-get-allowed-symbols';
import { RUN_EXPERT_MUTATION, RunExpertMutationData, RunExpertMutationError, RunExpertMutationValues } from '@root/modules/experts/mutations/run-expert.mutation';
import { expertsSlice } from '@root/modules/experts/store/experts.slice';
import { IExpert } from '@root/modules/experts/types/expert';
import { Logger } from '@root/shared/utils/logger';
import { notify } from '@root/shared/utils/notification';

export const useRunExpert = () => {
  const dispatch = useDispatch();
  const userId = useSelector(authSelector.userId);
  const { data: supportedSymbols } = useGetSymbols();
  const { data: accounts, refetch } = useGetAccounts();
  const [loading, setLoading] = useState<boolean>(false);
  const { getSymbols } = useGetAllowedSymbols();

  const mutationOptions = {
    onSuccess: () => {
      queryClient.invalidateQueries(['experts']);
    },
    onError: (error: Error) => notify({ type: 'danger', text: error.message }),
  };

  const runExpertMutation = useMutation<RunExpertMutationData, RunExpertMutationError, RunExpertMutationValues>(RUN_EXPERT_MUTATION, mutationOptions);

  const runExpert = useCallback(
    async (data: IExpert) => {
      setLoading(true);
      const brokerAccount = accounts?.find((item) => item.id === data.accountId);

      const allowedSymbols = getSymbols(data.accountId);
      const symbols = data.symbols.filter((symbol) => allowedSymbols?.includes(symbol));

      if (!brokerAccount) {
        setLoading(false);
        return dispatch(
          expertsSlice.actions.setEditExpertData({
            account: '',
            id: data.id,
            symbols: symbols,
          }),
        );
      }

      if (data.accountId && !brokerAccount?.isSignedIn) {
        const response = await signInAccountService({
          accountId: data.accountId as string,
        });

        if (response.status === 200) {
          refetch();

          if (!data.isEnabled) {
            dispatch(
              expertsSlice.actions.runExpertQueueAdd({
                expertId: data.id,
                accountId: data.accountId as string,
                symbols: symbols,
              }),
            );
          }
          setLoading(false);
          return;
        } else {
          notify({
            type: 'danger',
            title: response.payload,
          });

          Logger.captureMessage('Error while sign in account(use-run-expert)');
          setLoading(false);
          return;
        }
      }

      runExpertMutation.mutate({ id: data.id, symbols });
      setLoading(false);
    },
    [dispatch, runExpertMutation, userId, refetch, supportedSymbols, accounts, getSymbols],
  );

  return { runExpert, isLoading: runExpertMutation.isLoading || loading };
};
