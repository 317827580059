import clsx from 'clsx';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { useGetAccountCurrency } from '@root/modules/accounts/hooks/use-get-account-currency';
import { GET_EXPERT_STATS_DATA } from '@root/modules/experts/queries/get-expert-stats-data.query';
import { IExpert } from '@root/modules/experts/types/expert';
import { TradeStat } from '@root/modules/orders/components/trade-statistic';
import { ChevronIcon } from '@root/shared/icons/chevron-icon';
import { Text } from '@root/shared/ui/typography';

type Props = {
  expert: IExpert;
};

export const DetailedStats: FC<Props> = ({ expert }) => {
  const [statVisible, setStatVisible] = useState<boolean>(false);
  const { t } = useTranslation('forex-experts');

  const { currency } = useGetAccountCurrency(expert.accountId);

  const { data, isLoading } = useQuery(['fx', { id: expert.id }, 'expert-statistic'], GET_EXPERT_STATS_DATA, {
    enabled: !!expert.id,
    staleTime: 1000 * 60 * 10,
  });

  return (
    <div className='w-full px-5 py-2.5 bg-gray-800 rounded'>
      <div className='flex items-center gap-2 text-light-100 cursor-pointer' onClick={() => setStatVisible((prev) => !prev)}>
        <ChevronIcon className={clsx('transform transition-500', { 'rotate-180': !statVisible })} />
        <Text className='font-semibold'>{t('detailedStat')}</Text>
      </div>
      {data && statVisible && (
        <div className='mt-5 mb-2.5'>
          <TradeStat loading={isLoading} data={data} currency={currency} />
        </div>
      )}
    </div>
  );
};
