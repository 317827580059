import clsx from 'clsx';
import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useGetAccounts } from '@root/modules/accounts/hooks/use-get-accounts';
import { useGhostLogin } from '@root/shared-files/modules/auth/hooks/use-ghost-login';
import { ExpertStatusBadge } from '@root/modules/experts/components/expert-status';
import { useProviderTraders } from '@root/modules/marketplace/hooks/use-provider-traders';
import { useGetOrdersCount } from '@root/modules/orders/hooks/use-get-orders-count';
import { LoadingIcon } from '@root/shared/icons/loading-icon';
import { PlayIcon } from '@root/shared/icons/play-icon';
import { StopIcon } from '@root/shared/icons/stop-icon';
import { ImageWithFallback } from '@root/shared/ui/image/image-with-fallback';
import { InfoTooltip } from '@root/shared/ui/info-tooltip';
import { Table } from '@root/shared/ui/table';
import { Text } from '@root/shared/ui/typography';

import { useExpertItem } from '../hooks/use-expert-item';
import { IExpert } from '../types/expert';
import { RemoveExpertAssistModal } from './remove-expert-assist-modal';
import { StopExpertAssistModal } from './stop-expert-assist-modal';
import { globalRound } from '@root/shared/utils/number/round';
import { getGainLostColor } from '@root/shared/utils/colors';

type Props = {
  item: IExpert;
  pnl: number;
};

export const ExpertsTableRow: FC<Props> = ({ item, pnl }) => {
  const [showAllPairs, setShowAllPairs] = useState<boolean>(false);
  const { t } = useTranslation('forex-experts');
  const { traders } = useProviderTraders();
  const selectedTrader = useMemo(() => traders.find((trader) => trader.providerId === item.providerId), [traders, item.providerId]);
  const {
    tableActions,
    isPaused,
    isEnabled,
    status,
    stopRunMutation,
    mutationOptions,
    removeIsOpen,
    deleteMutation,
    removeIsDisabled,
    isLoading,
    stopIsOpen,
    setRemoveIsOpen,
    runExpert,
    setStopIsOpen,
    handleStopExpert,
  } = useExpertItem(item);

  const { hideActions } = useGhostLogin();

  const { historyOrdersCountByExpertId, activeOrdersCountByExpertId } = useGetOrdersCount(item.id);

  const { data: accounts } = useGetAccounts();
  const brokerServerName = useMemo(() => accounts?.find((account) => account.id === item?.accountId)?.server || '', [accounts, item?.accountId]);

  const allSymbols = item.symbols;
  const displaySymbols = item.symbols.slice(0, 3);
  const hiddenSymbols = item.symbols.slice(3);

  return (
    <Table.Row className='align-top border-t border-grayscale-600'>
      <Table.DataCell className='!pl-0' valign='middle'>
        <Link to={`/assists/${item.id}`}>
          <div className='flex items-center gap-x-2'>
            {(item.allowClone || item.allowShare) && <div className='w-2 h-2 rounded-lg bg-success-400 flex-shrink-0' />}
            <Text size='sm' className='text-primary-400'>
              {item.name}
            </Text>
          </div>
        </Link>
      </Table.DataCell>
      <Table.DataCell valign='middle'>
        {/* TODO: Show account info */}
        <Text size='sm'>
          {item?.account?.user} {brokerServerName}
        </Text>
      </Table.DataCell>
      <Table.DataCell valign='middle'>
        {selectedTrader ? (
          <div className='flex justify-start items-center gap-x-2'>
            <ImageWithFallback width={20} height={20} src={selectedTrader.logo} label={selectedTrader?.name?.substring(2)} />
            <Text size='sm'>{selectedTrader?.name || '-'}</Text>
          </div>
        ) : (
          <Text size='sm'>-</Text>
        )}
      </Table.DataCell>

      <Table.DataCell valign='middle'>
        <Text size='sm'>
          {activeOrdersCountByExpertId}/{historyOrdersCountByExpertId}
        </Text>
      </Table.DataCell>

      <Table.DataCell valign='middle'>
        <Text size='sm' className={`whitespace-nowrap ${getGainLostColor(pnl)}`}>
          $ {globalRound(pnl, 2)}
        </Text>
      </Table.DataCell>

      <Table.DataCell valign='middle'>
        {/* TODO: show template info */}
        {item.symbols.length ? (
          <Text size='sm'>
            {showAllPairs ? allSymbols.join(', ') : displaySymbols.join(', ')}
            {hiddenSymbols.length ? (
              <span className='underline pl-2 cursor-pointer' onClick={() => setShowAllPairs((prev) => !prev)}>
                {showAllPairs ? t('actions.hidePairs') : `+${hiddenSymbols.length}`}
              </span>
            ) : null}
          </Text>
        ) : (
          <Text size='sm'>-</Text>
        )}
      </Table.DataCell>
      <Table.DataCell valign='middle'>
        <ExpertStatusBadge status={status} />
      </Table.DataCell>
      <Table.DataCell valign='middle'>
        <div className={clsx('flex flex-shrink-0 gap-x-2 items-center')}>
          {tableActions.map((button, index) => (
            <InfoTooltip
              key={index}
              content={
                <Text className='w-full text-center' size='sm'>
                  {button.hoverText}
                </Text>
              }
            >
              <button
                disabled={button.isDisabled}
                onClick={button.onClick}
                className={clsx('text-base mt-1.5', {
                  'hover:opacity-80 transition': !button.isDisabled,
                  'text-grayscale-600': button.isDisabled,
                })}
              >
                <button.icon />
              </button>
            </InfoTooltip>
          ))}
          {isEnabled ? (
            <InfoTooltip
              content={
                <Text className='w-full text-center' size='sm'>
                  {t('item.actions.stop')}
                </Text>
              }
            >
              <button
                className={clsx('rounded bg-transparent flex justify-center items-center', {
                  'hover:opacity-80 transition': hideActions,
                  'text-grayscale-600': hideActions,
                })}
                onClick={handleStopExpert}
                disabled={hideActions}
              >
                {stopRunMutation.isLoading ? <LoadingIcon /> : <StopIcon />}
              </button>
            </InfoTooltip>
          ) : (
            <InfoTooltip
              content={
                <Text className='w-full text-center' size='sm'>
                  {t('item.actions.run')}
                </Text>
              }
            >
              <button className='rounded bg-transparent flex justify-center items-center' onClick={() => runExpert(item)} disabled={hideActions}>
                {isLoading ? <LoadingIcon /> : <PlayIcon />}
              </button>
            </InfoTooltip>
          )}
        </div>
      </Table.DataCell>
      <RemoveExpertAssistModal
        removeIsOpen={removeIsOpen}
        setRemoveIsOpen={setRemoveIsOpen}
        onDelete={() => deleteMutation.mutate(item.id, mutationOptions)}
        isLoading={deleteMutation.isLoading}
        removeIsDisabled={removeIsDisabled}
      />
      <StopExpertAssistModal
        stopIsOpen={stopIsOpen}
        setStopIsOpen={setStopIsOpen}
        onStop={() => {
          stopRunMutation.mutate(item.id, mutationOptions);
          setStopIsOpen(false);
        }}
        isLoading={stopRunMutation.isLoading}
      />
    </Table.Row>
  );
};
