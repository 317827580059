import { useFormikContext } from 'formik';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ManualStopLossTakeProfit } from '@root/modules/experts/components/manual-stop-loss-take-profit';
import { SignalStopLossTakeProfit } from '@root/modules/experts/components/signal-stop-loss-take-profit';
import { TrailingStop } from '@root/modules/experts/components/trailing-stop';
import { CreateExpertDto } from '@root/modules/experts/dtos/create-expert.dto';
import { useCreateExpertFieldOptions } from '@root/modules/experts/hooks/use-create-expert-options';
import { CreateExpertStepKey } from '@root/modules/experts/hooks/use-create-expert-steps';
import { useExpertPartialEdit } from '@root/modules/experts/hooks/use-expert-partial-edit';
import { IExpert } from '@root/modules/experts/types/expert';
import { Text } from '@root/shared/ui/typography';

import { EditableSettingsTitle } from './editable-settings-title';

type Props = {
  expert: IExpert;
  editable?: boolean;
};

export const StopLossAndTakeProfit: FC<Props> = ({ editable = true }) => {
  const { t } = useTranslation('forex-experts');
  const { editing, loading, saveSettings, cancelChanges, setEditing } = useExpertPartialEdit(CreateExpertStepKey.StopLossTakeProfit);
  const { values } = useFormikContext<CreateExpertDto>();

  const options = useCreateExpertFieldOptions();

  const manualTpOption = options.manualStopLossTakeProfitType.find((option) => option.value === values.manualSlTp.type);
  const signalSlTpOption = options.signalStopLossTakeProfit.find((option) => option.value === values.signalSlTp.type);

  const profitCalculation = options.smartEntryProfitCalculationType.find((option) => option.value === values.signalSlTp.profitCalculationType);
  const slType = options.slTypeOptions.find((option) => option.value === values.signalSlTp.slType);
  const rrRatio = options.rrRatioOptions.find((option) => option.value === values.signalSlTp.rrRatio);

  return (
    <div>
      <EditableSettingsTitle
        editDisabled={false}
        title={t('steps.stopLossTakeProfit.title')}
        loading={loading}
        editing={editing}
        setEditing={setEditing}
        saveSettings={saveSettings}
        cancelChanges={cancelChanges}
        section={CreateExpertStepKey.StopLossTakeProfit}
        editable={editable}
      />
      {editing ? (
        <div className='w-full px-4 py-3 rounded bg-grayscale-700'>
          <div className='pb-2'>
            {values.strategy.type === 'manual' && <ManualStopLossTakeProfit type={values.manualSlTp.type} unitType={values.unitType} isPartialEdit={true} />}
            {values.strategy.type === 'signal' && <SignalStopLossTakeProfit isPartialEdit />}
          </div>
          <TrailingStop use={values.trailStop.use} unitType={values.unitType} />
        </div>
      ) : (
        <div className='w-full px-4 py-3 rounded flex flex-wrap justify-start items-center gap-1 bg-grayscale-700'>
          {values.strategy.type === 'manual' && (
            <>
              <div className='w-full flex justify-between flex-wrap gap-x-4 items-center'>
                <Text size='base' className='text-gray-500 flex-shrink-0'>
                  {t('fields.manualStopLossTakeProfit.type.label')}
                </Text>
                <Text size='base' className='text-right'>
                  {manualTpOption?.label}
                </Text>
              </div>
              <div className='w-full flex justify-between flex-wrap gap-x-4 items-center'>
                <Text size='base' className='text-gray-500 flex-shrink-0'>
                  {t('fields.manualStopLossTakeProfit.fixedSl.label')}
                </Text>
                <Text size='base' className='text-right'>
                  {values.manualSlTp.fixedSl} {values.unitType}
                </Text>
              </div>
              {['0'].includes(values.manualSlTp.type) && (
                <div className='w-full flex justify-between flex-wrap gap-x-4 items-center'>
                  <Text size='base' className='text-gray-500 flex-shrink-0'>
                    {t('fields.manualStopLossTakeProfit.fixedSl.label')}
                  </Text>
                  <Text size='base' className='text-right'>
                    {values.manualSlTp.fixedTp} {values.unitType}
                  </Text>
                </div>
              )}
              {['1'].includes(values.manualSlTp.type) && (
                <div className='w-full flex justify-between flex-wrap gap-x-4 items-center'>
                  <Text size='base' className='text-gray-500 flex-shrink-0'>
                    {t('fields.manualStopLossTakeProfit.tpRatio.label')}
                  </Text>
                  <Text size='base' className='text-right'>
                    {values.manualSlTp.tpRatio} {values.unitType}
                  </Text>
                </div>
              )}
            </>
          )}
          {values.strategy.type === 'signal' && (
            <>
              <div className='w-full flex justify-between flex-wrap gap-x-4 items-center'>
                <Text size='base' className='text-gray-500 flex-shrink-0'>
                  {t('fields.signalStopLossTakeProfit.type.label')}
                </Text>
                <Text size='base' className='text-right'>
                  {signalSlTpOption?.label}
                </Text>
              </div>
              {['0', '1'].includes(values.signalSlTp.type) && (
                <div className='w-full flex justify-between flex-wrap gap-x-4 items-center'>
                  <Text size='base' className='text-gray-500 flex-shrink-0'>
                    {t('fields.signalStopLossTakeProfit.fixedSl.label')}
                  </Text>
                  <Text size='base' className='text-right'>
                    {values.signalSlTp.fixedSl} {values.unitType}
                  </Text>
                </div>
              )}
              {['0'].includes(values.signalSlTp.type) && (
                <div className='w-full flex justify-between flex-wrap gap-x-4 items-center'>
                  <Text size='base' className='text-gray-500 flex-shrink-0'>
                    {t('fields.signalStopLossTakeProfit.fixedTp.label')}
                  </Text>
                  <Text size='base' className='text-right'>
                    {values.signalSlTp.fixedTp} {values.unitType}
                  </Text>
                </div>
              )}
              {['1'].includes(values.signalSlTp.type) && (
                <div className='w-full flex justify-between flex-wrap gap-x-4 items-center'>
                  <Text size='base' className='text-gray-500 flex-shrink-0'>
                    {t('fields.signalStopLossTakeProfit.tpRatio.label')}
                  </Text>
                  <Text size='base' className='text-right'>
                    {values.signalSlTp.tpRatio}
                  </Text>
                </div>
              )}
              {['4'].includes(values.signalSlTp.type) && (
                <>
                  <div className='w-full flex justify-between flex-wrap gap-x-4 items-center'>
                    <Text size='base' className='text-gray-500 flex-shrink-0'>
                      {t('fields.signalStopLossTakeProfit.profitCalculationType.label')}
                    </Text>
                    <Text size='base' className='text-right'>
                      {profitCalculation?.label || ''}
                    </Text>
                  </div>
                  <div className='w-full flex justify-between flex-wrap gap-x-4 items-center'>
                    <Text size='base' className='text-gray-500 flex-shrink-0'>
                      {t('fields.signalStopLossTakeProfit.slType.label')}
                    </Text>
                    <Text size='base' className='text-right'>
                      {slType?.label || ''}
                    </Text>
                  </div>
                  {['0'].includes(profitCalculation?.value || '') && (
                    <div className='w-full flex justify-between flex-wrap gap-x-4 items-center'>
                      <Text size='base' className='text-gray-500 flex-shrink-0'>
                        {t('fields.signalStopLossTakeProfit.rrRatio.label')}
                      </Text>
                      <Text size='base' className='text-right'>
                        {rrRatio?.label || ''}
                      </Text>
                    </div>
                  )}
                  {['1'].includes(profitCalculation?.value || '') && (
                    <div className='w-full flex justify-between flex-wrap gap-x-4 items-center'>
                      <Text size='base' className='text-gray-500 flex-shrink-0'>
                        {t('fields.signalStopLossTakeProfit.smartFixedTp.label')}
                      </Text>
                      <Text size='base' className='text-right'>
                        {values.signalSlTp.fixedTp || ''} {values?.unitType}
                      </Text>
                    </div>
                  )}
                </>
              )}
            </>
          )}
          <div className='w-full flex justify-between flex-wrap gap-x-4 items-center'>
            <Text size='base' className='text-gray-500 flex-shrink-0'>
              {t('fields.trailingStop.use.label')}
            </Text>
            <Text size='base' className='text-right'>
              {values.trailStop.use ? t('on') : t('off')}
            </Text>
          </div>
          {values.trailStop.use && (
            <>
              <div className='w-full flex justify-between flex-wrap gap-x-4 items-center'>
                <Text size='base' className='text-gray-500 flex-shrink-0'>
                  {t('fields.trailingStop.fromX.label')}
                </Text>
                <Text size='base' className='text-right'>
                  {values.trailStop.afterX} {values.unitType}
                </Text>
              </div>
              <div className='w-full flex justify-between flex-wrap gap-x-4 items-center'>
                <Text size='base' className='text-gray-500 flex-shrink-0'>
                  {t('fields.trailingStop.toY.label')}
                </Text>
                <Text size='base' className='text-right'>
                  {values.trailStop.followY} {values.unitType}
                </Text>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};
