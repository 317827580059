import { FxAlertEvent } from '@3lgn/shared/dist/constants/webhooks';

import { useFormikContext } from 'formik';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { copyToClipboard } from '@root/shared/utils/helpers/copy-to-clipboard';

import { alertOptions } from '../constants/alert-options';
import { Alert } from '../types/alert-form';
import { useGetExpert } from './use-get-expert';

const jsonFieldsMap = {
  symbol: [
    FxAlertEvent.START_LONG_MARKET_DEAL,
    FxAlertEvent.START_SHORT_MARKET_DEAL,
    FxAlertEvent.START_LONG_LIMIT_DEAL,
    FxAlertEvent.START_SHORT_LIMIT_DEAL,
    FxAlertEvent.CLOSE_ALL_ORDERS_AT_MARKET_PRICE,
  ],
  price: [
    FxAlertEvent.START_LONG_LIMIT_DEAL, 
    FxAlertEvent.START_SHORT_LIMIT_DEAL
  ],
};

const isFieldAvailable = (field, type) => {
  return jsonFieldsMap[field].find((fieldType) => fieldType === type) || false;
};

export const useAlertFields = (expertId: string) => {
  const { t } = useTranslation('forex-experts');
  const { values, setFieldTouched, setFieldValue } = useFormikContext<Alert>();

  const { data: expert, isLoading } = useGetExpert(expertId || '', true);
  const [generatedData, setGeneratedData] = useState<{ [key: string]: string }>({});

  const alertNameOptions = useMemo(() => {
    return alertOptions.map((item) => ({
      label: t(item.label),
      value: item.value,
    }));
  }, [t]);

  const symbolOptions = useMemo(() => {
    return expert ? expert.symbols.map((item) => ({ value: item, label: item })) : [];
  }, [expert]);

  const nameDescriptionKey = useMemo(() => alertOptions.find((item) => item.value === values.type)?.description || '', [values.type]);

  const isSymbolAvailable = useMemo(() => {
    return jsonFieldsMap['symbol'].includes(values.type);
  }, [values.type]);

  const handleSymbolChange = useCallback(
    (pair) => {
      setFieldTouched('pair', true);
      setFieldValue('pair', pair);
    },
    [setFieldTouched, setFieldValue],
  );

  // eslint-disable-next-line @typescript-eslint/ban-types
  const copyElement = useCallback((element: string | Object) => {
    if (typeof element === 'string') {
      copyToClipboard(element);
    } else {
      copyToClipboard(JSON.stringify(element));
    }
  }, []);

  useEffect(() => {
    if (expert) {
      const data = {
        type: values.type,
        assistId: expertId,
        source: 'forex',
        symbol: isFieldAvailable('symbol', values.type) ? values.symbol : undefined,
        date: '{{time}}',
        price: isFieldAvailable('price', values.type) ? '{{close}}' : undefined,
      };

      setGeneratedData(JSON.parse(JSON.stringify(data)));
    }
  }, [expertId, values, expert]);

  const state = {
    nameDescriptionKey,
    alertNameOptions,
    isLoading,
    symbolOptions,
    generatedData,
    isSymbolAvailable,
  };

  const handlers = {
    copyElement,
    handleSymbolChange,
    setGeneratedData,
  };

  return [state, handlers] as [typeof state, typeof handlers];
};
