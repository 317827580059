import { chartLocales } from '@root/shared/constants/chart-locales';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { FC, useMemo } from 'react';
import Chart from 'react-apexcharts';
import type { Props as ChartProps } from 'react-apexcharts';
import styled, { useTheme } from 'styled-components';

const StyledContainer = styled.div`
  .tooltip-box {
    background: ${({ theme }) => theme.grayscale[700]};
    border: ${({ theme }) => `0px solid ${theme.grayscale[700]}`};
    padding: ${({ theme }) => `${theme.spacing(1)}px ${theme.spacing(2)}px`};
    color: ${({ theme }) => theme.gray[100]};
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => `${theme.spacing(1)}px`};
  }

  .percent {
    display: block;
    position: relative;
    padding-left: ${({ theme }) => `${theme.spacing(4)}px`};

    &:before {
      display: block;
      content: '';
      position: absolute;
      width: 6px;
      height: 6px;
      border-radius: 100px;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      background: ${({ theme }) => theme.success[400]};
    }
  }
`;

export type ProviderAnalyticsChartProps = {
  data: { date: Date; value: number }[];
};

export const ProviderAnalyticsChart: FC<ProviderAnalyticsChartProps> = ({ data }) => {
  const theme = useTheme();
  const {
    i18n: { language },
  } = useTranslation();

  const chartProps = useMemo<ChartProps>(() => {
    return {
      type: 'area',
      height: 224,
      options: {
        colors: [theme.success['400']],
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 0.5,
            opacityFrom: 0.7,
            opacityTo: 0,
            stops: [0, 100],
          },
        },
        grid: {
          borderColor: theme.muted['600'],
          xaxis: { lines: { show: true } },
          yaxis: { lines: { show: true } },
        },
        stroke: {
          curve: 'straight',
        },
        dataLabels: {
          enabled: false,
        },
        tooltip: {
          theme: 'dark',
          custom: function ({ series, seriesIndex, dataPointIndex, w }) {
            return `
              <div class="tooltip-box text-base">
                <span class="font-bold percent">${series[seriesIndex][dataPointIndex]}%</span>
                <span class="text-grayscale-500 text-sm date">${dayjs(w.config.series[0].data[dataPointIndex][0]).locale(language).format('dddd, MMMM DD, YYYY')}</span>
              </div>
            `;
          },
        },
        markers: {
          size: 6,
          strokeWidth: 0,
        },
        legend: {
          show: false,
          labels: { colors: theme.muted['400'] },
        },
        xaxis: {
          axisTicks: { show: false },
          axisBorder: { show: false },
          labels: {
            style: { colors: theme.muted['400'] },
            datetimeFormatter: {
              month: 'MMMM',
              day: 'dd MMM',
            },
          },
          type: 'datetime',
          forceNiceScale: true,
          tooltip: {
            enabled: false,
          },
        },
        yaxis: {
          forceNiceScale: true,
          labels: {
            style: {
              colors: theme.muted['400'],
            },
            formatter: (value, options) => {
              if (typeof options !== 'object' || options?.dataPointIndex === -1) {
                return value.toLocaleString() + '%';
              }

              const dataIndex = options.dataPointIndex;
              const balance = data?.[dataIndex]?.value?.toLocaleString() || '';

              return balance + '%';
            },
          },
        },
        chart: {
          locales: chartLocales,
          defaultLocale: 'en',
          toolbar: { show: false },
          zoom: { enabled: false },
          id: 'providersAnalyticsChart',
        },
      },
      series: [
        {
          name: 'Gain',
          data: data.map((item) => [item.date, item.value]),
        },
      ],
    };
  }, [data, theme.muted, theme.success, language]);

  return (
    <div className='bg-gray-800 py-4 md:p-4'>
      <StyledContainer className='-my-4'>
        <Chart {...chartProps} />
      </StyledContainer>
    </div>
  );
};
