import { AxiosError } from 'axios';

import { fetcher } from '@root/infra/fetcher';
import { Profit } from '@root/modules/orders/types/profit';
import { handleAxiosError } from '@root/shared/utils/helpers/handle-axios-error';
import type { IHttpResponse } from '@root/shared/utils/http-service';
import { stringifyParams } from '@root/shared/utils/string/query-stringify';
import { Id } from '@root/shared/utils/types';

export type GetClosedOrdersPnLServiceResponse = IHttpResponse<200, Profit> | IHttpResponse<400, string>;
export type GetClosedOrdersPnLServiceQuery = { userId: Id; accounts: Id; expertId?: string };

export const getClosedOrdersPnL = async ({ accounts, expertId }: GetClosedOrdersPnLServiceQuery): Promise<GetClosedOrdersPnLServiceResponse> => {
  try {
    const stringifiedParams = stringifyParams({
      accounts: accounts === 'all' || !accounts ? undefined : accounts,
      experts: expertId,
    });

    const response = await fetcher.get(`/users/orders/closed/profit?${stringifiedParams}`, {
      baseURL: import.meta.env.VITE_FX_CORE_HOST,
    });

    return {
      status: 200,
      payload: response.data,
    };
  } catch (e) {
    return handleAxiosError(e as AxiosError);
  }
};
