import { AbsolutePortal } from '@root/infra/portals';
import { queryClient } from '@root/infra/query';
import { ChevronIcon } from '@root/shared/icons/chevron-icon';
import { CopyIcon } from '@root/shared/icons/copy-icon';
import { CrossIcon } from '@root/shared/icons/cross-icon';
import { InfoIcon } from '@root/shared/icons/info-icon';
import { Button } from '@root/shared/ui/button';
import { InfoTooltip } from '@root/shared/ui/info-tooltip';
import { Toggler } from '@root/shared/ui/toggler/toggler';
import { Text } from '@root/shared/ui/typography';
import { copyToClipboard } from '@root/shared/utils/helpers/copy-to-clipboard';
import { notify } from '@root/shared/utils/notification';
import clsx from 'clsx';
import { Dispatch, FC, SetStateAction, useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { IExpert } from '@root/modules/experts/types/expert';
import { updateExpertService } from '@root/modules/experts/services/update-expert.service';
import { ShareDotsIcon } from '@root/shared/icons/share-dots-icon';

const Wrapper = styled.div<{ isGridView: boolean }>`
  position: ${({ isGridView }) => isGridView ? 'absolute' : 'fixed'};
  top: ${({ isGridView }) => isGridView ? 'unset' : '120px'};
  bottom: ${({ isGridView }) => isGridView ? '20px' : 'unset'};
  right: ${({ isGridView }) => isGridView ? '0' : '16px'};
  width: 460px;
  box-shadow: 0px 10px 24px 0px rgba(12, 12, 12, 0.6);
  z-index: 20;
`;

const OnlineLink = styled.div`
    padding-left: 12px;
    position: relative;
    
    &::before {
        content: '';
        position: absolute;
        border-radius: 50%;
        width: 8px;
        height: 8px;
        top: calc(50% - 4px);
        left: 0;
        background-color: ${({ theme }) => theme.success[400]};    
    }
`

type Props = {
    bot: IExpert;
}

const ShareAssistContent: FC<{ bot: IExpert, setIsOpen: Dispatch<SetStateAction<boolean>> }> = ({ bot, setIsOpen }) => {
    const { t } = useTranslation('forex-experts');
    const { allowClone, allowShare } = bot;

    const handleShare = useCallback(async () => {
        const response = await updateExpertService(bot.id as string, { allowShare: !bot.allowShare, allowClone: !bot.allowShare });

        if (response.status === 200) {
            queryClient.invalidateQueries(['experts', bot.id]);
        } else {
            notify({
                title: response.payload,
                type: 'danger'
            });
        }
    }, [bot.id, bot.allowShare]);

    const handleClone = useCallback(async () => {
        const response = await updateExpertService(bot.id as string, { allowClone: !bot.allowClone });

        if (response.status === 200) {
            queryClient.invalidateQueries(['experts', bot.id]);
        } else {
            notify({
                title: response.payload,
                type: 'danger'
            });
        }
    }, [bot.id, bot.allowClone]);

    const link = `${window.location.origin}/share/${bot.id}`;

    const handleCopy = useCallback(() => {
        copyToClipboard(link);
        notify({
            title: t('sharing.copied'),
            type: 'success'
        })
    }, [link]);

    return <Wrapper className='bg-gray-900 rounded p-4' isGridView={false}>
        <div className="w-full flex justify-between items-center gap-4">
            <Text size='base' className='text-gray-100'>{t('sharing.title')}</Text>
            <CrossIcon width={12} height={12} className='cursor-pointer' onClick={() => setIsOpen(false)} />
        </div>
        {
            !allowShare && <div>
                <Text size='sm' className='text-grayscale-500 mb-4'>{t('sharing.description')}</Text>
                <Button className='w-full' onClick={handleShare}>{t('sharing.share')}</Button>
            </div>
        }
        {
            allowShare && <div>
                <OnlineLink>
                    <Text size='sm' className='text-success-400'>
                        {t('sharing.visibleToAnyone')}
                    </Text>
                </OnlineLink>
                <div className='w-full rounded flex items-stretch border border-grayscale-600 overflow-hidden mb-4'>
                    <div className='flex-grow bg-grayscale-800 text-grayscale-300 overflow-hidden p-2'>
                        <Text size='sm' className='overflow-hidden whitespace-nowrap text-ellipsis'>
                            {link}
                        </Text>
                    </div>
                    <button className='flex-shrink-0 w-10 flex justify-center items-center border-l border-grayscale-600 hover:bg-primary-400' onClick={handleCopy}>
                        <CopyIcon />
                    </button>
                </div>
                <div className='flex items-center gap-x-4 mb-4'>
                    <Toggler checked={allowClone} onChange={handleClone} />
                    <InfoTooltip content={<Text size='sm'>{t('sharing.cloneHint')}</Text>}>
                        <div className='w-full flex items-center gap-x-2'>
                            <Text size='sm'>{t('sharing.allowToClone')}</Text>
                            <InfoIcon />
                        </div>
                    </InfoTooltip>
                </div>
                <Button className='w-full' onClick={handleShare} outlined>{t('sharing.stopSharing')}</Button>
            </div>
        }
    </Wrapper>
}

export const ShareAssistButton: FC<Props> = ({ bot }) => {
    const ref = useRef<HTMLDivElement>(null);
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const { allowShare } = bot;

    return <div ref={ref} className='relative'>
        <Button variant='primary' className={clsx('relative !px-2 !py-1.5', {
            '!bg-primary-400 !text-gray-100 !outline-none hover:!border-primary-400': isOpen,
        })} onClick={() => setIsOpen(!isOpen)} outlined>
            <div className="flex items-center gap-x-2">
                <ShareDotsIcon />
                <ChevronIcon width={10} height={10} className={clsx('transition-transform duration-200', { 'rotate-180': !isOpen })} />
            </div>
            {allowShare && !isOpen && <div className='absolute w-2 h-2 bg-success-400 rounded-lg top-[-3px] right-[-4px]' />}
        </Button>
        <AbsolutePortal>
            {isOpen && <ShareAssistContent bot={bot} setIsOpen={setIsOpen} />}
        </AbsolutePortal>
    </div>;
}

