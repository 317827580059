import { externalRedirect } from '@root/shared/utils/helpers/external-redirect';
import { useTranslation } from 'react-i18next';
import { StockIcon } from '@root/shared/icons/stock-icon';
import { InfoTooltip } from '@root/shared/ui/info-tooltip';
import { FC, useCallback, useState } from 'react';
import { LayoutElements } from './layout.elements';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { userSettingsSelector } from '@root/modules/user-settings/store/user-settings.selector';
import clsx from 'clsx';
import { GenieGptIcon } from '@root/shared/icons/genie-gpt';
import { FxIcon } from '@root/shared/icons/fx-icon';
import { CryptoIcon } from '@root/shared/icons/crypto-icon';

const MainLinkWrapper = styled.div<{ isOpen: boolean }>`
  width: ${({ isOpen }) => (isOpen ? '200px' : '64px')};
`;

const { HeaderLink, NestedHeaderLink } = LayoutElements.Header;

const MainLink: FC<{ isOpen: boolean }> = ({ isOpen }) => {
  const { t } = useTranslation('common');
  const menuOpened = useSelector(userSettingsSelector.menuOpened);

  return (
    <MainLinkWrapper className='relative flex items-center' isOpen={menuOpened}>
      <HeaderLink
        icon={<FxIcon className='text-[19px]' />}
        isOpen={isOpen}
        title={menuOpened ? t('sideBar.fx') : ''}
        className={clsx('w-full flex', { '!flex-row !gap-x-4 !justify-center px-4': menuOpened, 'gap-y-2 !justify-center': !menuOpened })}
      />
    </MainLinkWrapper>
  );
};

export const HeaderLinksDropdown = () => {
  const { t } = useTranslation('common');
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const menuOpened = useSelector(userSettingsSelector.menuOpened);

  const redirectToCryptoSite = useCallback(() => {
    externalRedirect(import.meta.env.VITE_CRYPTO_SITE);
  }, []);

  const redirectToGpt = useCallback(() => {
    externalRedirect(import.meta.env.VITE_CHAT_LINK);
  }, []);

  return (
    <div className='relative bg-grayscale-800' onMouseEnter={() => setIsOpen(true)} onMouseLeave={() => setIsOpen(false)}>
      <MainLink isOpen={isOpen} />

      {isOpen && (
        <div className='flex flex-col gap-0 absolute top-0 left-0 bg-grayscale-800 rounded z-101'>
          <MainLink isOpen={isOpen} />

          <div onClick={redirectToCryptoSite}>
            <NestedHeaderLink isActive={false} title={menuOpened ? t('sideBar.crypto') : ''} icon={<CryptoIcon />} isOpen={menuOpened} />
          </div>
          <div onClick={redirectToGpt}>
            <NestedHeaderLink isActive={false} title={menuOpened ? (t('sideBar.masterGpt') as string) : ''} icon={<GenieGptIcon width={20} height={20} />} isOpen={isOpen} />
          </div>
          {/* <InfoTooltip content={<div>{t('releaseSoon')}</div>} positions={['right']}>
            <NestedHeaderLink disabled title={menuOpened ? t('sideBar.fx') as string : ''} icon={<FXIcon />} isOpen={isOpen} />
          </InfoTooltip> */}
          <InfoTooltip content={<div>{t('releaseSoon')}</div>} positions={['right']}>
            <NestedHeaderLink disabled title={menuOpened ? (t('sideBar.stock') as string) : ''} icon={<StockIcon />} isOpen={menuOpened} />
          </InfoTooltip>
        </div>
      )}
    </div>
  );
};
