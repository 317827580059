import styled from 'styled-components';

export const SelectButton = styled.button<{ selected: boolean, disabled?: boolean; }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  border: 1px solid ${({ theme, selected, disabled }) => (selected && !disabled ? theme.primary[400] : theme.grayscale[500])};
  border-radius: 13px;
  box-sizing: border-box;
  cursor: pointer;
  font-size: 20px;
  line-height: 32px;
  background: transparent;
  color: ${({ theme, selected, disabled }) => (disabled ? theme.grayscale[500] : selected ? theme.primary[400] : theme.grayscale[100])};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};

  &:hover {
    color: ${({ theme, selected, disabled }) => (disabled ? theme.grayscale[500] : selected ? theme.gray[100] : theme.primary[400])};
    border-color: ${({ theme, disabled }) => disabled ? theme.grayscale[500] : theme.primary[400]};
  }
`;