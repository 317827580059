import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from '@root/shared/ui/typography';
import { EnterCodeWithBackupForm, TwoFAAuthType } from '../../types/two-fa';
import { TextField } from '@root/shared/form';
import { Button } from '@root/shared/ui/button';
import { useFormikContext } from 'formik';
import { useDispatch } from 'react-redux';
import { authSlice } from '../../store';
import { deleteCookie } from '@root/shared/utils/cookies';

type Props = {
    type: TwoFAAuthType;
    canResetTwoFa?: boolean;
    onCancel?: () => void;
}

export const BackupCodeContent: FC<Props> = ({ canResetTwoFa, onCancel }) => {
    const { isSubmitting, setFieldValue } = useFormikContext<EnterCodeWithBackupForm>();
    const { t } = useTranslation('two-fa');
    const dispatch = useDispatch();

    const onResetClick = useCallback(() => {
        onCancel?.();
        dispatch(authSlice.actions.setResetTwoFaModalOpen(true));
    }, [dispatch, onCancel]);

    return <div className='w-full'>
        <Text size='base'>
            {t('enterBackupCode.text')}
        </Text>
        <div className='my-4'>
            <TextField
                name='backupCode'
                placeholder={t('enterBackupCode.placeholder')}
            />
        </div>
        {
            canResetTwoFa && <div className='flex'>
                <span className='text-primary-400 cursor-pointer' onClick={onResetClick}>{t('enterBackupCode.resetTwoFA')}</span>
            </div>
        }
        <div className="w-full flex justify-between items-center gap-x-4 mt-6">
            <Button onClick={() => setFieldValue('useBackupCode', false)} outlined>{t('enterBackupCode.cancel')}</Button>
            <Button loading={isSubmitting} type='submit'>{t('enterBackupCode.confirm')}</Button>
        </div>
    </div>
}




