import { AxiosError } from 'axios';

import { fetcher } from '@root/infra/fetcher';
import { handleAxiosError } from '@root/shared/utils/helpers/handle-axios-error';
import type { IHttpResponse } from '@root/shared/utils/http-service';

import { IExpert } from '../types/expert';
import { Id } from '@root/shared/utils/types';

export type GetExpertServiceResponse = IHttpResponse<200, IExpert> | IHttpResponse<400, string>;

type UpdateExpertOrderData = Pick<IExpert, 'breakEven' | 'partialClose' | 'trailStop'>;

export const updateExpertOrder = async (expertId: string, orderId: Id, data: UpdateExpertOrderData): Promise<GetExpertServiceResponse> => {
  try {
    const response = await fetcher.patch(`/experts/${expertId}/order/${orderId}`, data, { baseURL: import.meta.env.VITE_FX_CORE_HOST });

    return {
      status: 200,
      payload: response.data,
    };
  } catch (e) {
    return handleAxiosError(e as AxiosError);
  }
};
