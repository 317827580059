import { TabType } from '@root/modules/orders/enums/orders';
import { IOrder } from '@root/modules/orders/types/orders';
import { isLimitOrder } from '@root/modules/orders/utils/orders';
import { useGhostLogin } from '@root/shared-files/modules/auth/hooks/use-ghost-login';
import { PageLinks } from '@root/shared/constants/pageLinks';
import { CloseMenuIcon } from '@root/shared/icons/close-menu-icon';
import { CopyIcon } from '@root/shared/icons/copy-icon';
import { MoreIcon } from '@root/shared/icons/more';
import { ShareDotsIcon } from '@root/shared/icons/share-dots-icon';
import { InfoTooltip } from '@root/shared/ui/info-tooltip';
import { AmountWithCurrency } from '@root/shared/ui/money';
import { Table } from '@root/shared/ui/table';
import { getGainLostColor, getOrderSideColor } from '@root/shared/utils/colors';
import { copyToClipboard } from '@root/shared/utils/helpers/copy-to-clipboard';
import { globalRound } from '@root/shared/utils/number/round';
import { Text } from '@root/shared/ui/typography';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { FC, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SimplePopover } from '@root/modules/orders/components/table-popover';
import { ChevronIcon } from '@root/shared/icons/chevron-icon';

type Props = {
    order: IOrder;
    tab: TabType;
    expertNames: Record<string, string>;
    currency?: string;
    popover: any;
    isExpertsDetails?: boolean;
    shareOrder: (order: IOrder) => void;
    modifyOrder: (order: IOrder) => void;
    deleteOrder: (order: IOrder) => void;
    modifyTrade: (order: IOrder) => void;
    modifyPartialClose: (order: IOrder) => void;
}

const { Row, DataCellSlim } = Table;

export const TradesTableRow: FC<Props> = ({ order, tab, currency, expertNames, isExpertsDetails, popover, shareOrder, modifyOrder, deleteOrder, modifyTrade, modifyPartialClose }) => {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen((prev) => !prev);
    const { t } = useTranslation('dashboard', { keyPrefix: 'trades' });
    const { hideActions } = useGhostLogin();

    const unitType = !order.expertConfiguration?.usePips ? 'points' : 'pips';
    const openTime = order.openTime ? dayjs(order.openTime).local().format('DD/MM/YYYY hh:mm:ss A') : '-';
    const closeTime =
        order.closeTime || order.updatedAt
            ? dayjs(order.closeTime || order.updatedAt)
                .local()
                .format('DD/MM/YYYY hh:mm:ss A')
            : '-';
    const time = tab === TabType.HISTORY ? closeTime : openTime;
    const currentPrice = order?.takeProfit < order?.stopLoss ? order?.currentPrice?.ask : order?.currentPrice?.bid;

    const validChildOrders = useMemo(() => {
        return (order.childOrders?.filter((childOrder) => childOrder.status !== 'NOT_CLOSED') || []).sort((a, b) => b.profit - a.profit);
    }, [order.childOrders]);

    const hasChildOrders = validChildOrders.length > 0;

    return (
        <>
            <Row key={order.ticket} className='border-t border-gray-700 text-sm'>
                <DataCellSlim>
                    <div className='relative group cursor-pointer hover:text-gray-500' onClick={() => copyToClipboard(order.ticket)}>
                        <div>{order.ticket || '-'}</div>
                        <div className='absolute top-[5px] right-[-20px] bg-gray-800 hidden group-hover:block'>
                            <CopyIcon className='flex-shrink-0' width={16} height={16} />
                        </div>
                    </div>
                </DataCellSlim>
                {!isExpertsDetails && (
                    <DataCellSlim className='min-w-[200px]'>
                        {expertNames[order?.expertId] ? (
                            <Link className='text-primary-400' to={PageLinks.experts + `/${order?.expertId}`}>
                                {expertNames[order?.expertId]}
                            </Link>
                        ) : (
                            '-'
                        )}
                    </DataCellSlim>
                )}
                <DataCellSlim>{order.symbol || '-'}</DataCellSlim>
                <DataCellSlim className='min-w-[140px]'>{time}</DataCellSlim>
                <DataCellSlim>
                    <span className={clsx(`whitespace-nowrap bg-grayscale-700 py-1 px-3 rounded-full ${getOrderSideColor(order.type)} `)}>{order.type}</span>
                </DataCellSlim>
                <DataCellSlim>
                    <div className="flex justify-start items-center gap-2">
                        <span>
                            {order.lots}
                        </span>
                        {hasChildOrders && (
                            <button className='p-1 px-1.5 rounded bg-gray-700 text-xs flex items-center gap-2' onClick={toggle}>
                                <span className='text-base leading-none'>{(validChildOrders.length || 0) + 1}</span>
                                <ChevronIcon className={clsx('transform transition-200', { 'rotate-180': !isOpen })} />
                            </button>
                        )}
                    </div>
                </DataCellSlim>
                <DataCellSlim className='capitalize'>{order.source === 'EXTERNAL' ? t('external') : order.openedBy}</DataCellSlim>
                <DataCellSlim>{globalRound(order.openPrice, 6)}</DataCellSlim>
                <DataCellSlim> {tab === 'history' ? globalRound(order.closePrice, 6) : globalRound(currentPrice, 6) || '-'}</DataCellSlim>
                <DataCellSlim>
                    <span className='whitespace-nowrap'>
                        <span className={`ml-1 ${getGainLostColor(order.profit)}`}>
                            <AmountWithCurrency currency={currency} value={globalRound(order.profit, 2)} />
                        </span>
                        <span className={`ml-1 ${getGainLostColor(order.calculatedUnits)}`}>{globalRound(order.calculatedUnits, 0)} {unitType}</span>
                    </span>
                </DataCellSlim>
                <DataCellSlim>
                    {tab === 'history' ? <span className='whitespace-nowrap bg-gray-700 py-1 px-3 rounded-full'>{t('statuses.closed')}</span> : isLimitOrder(order.type) ? (
                        <span className='whitespace-nowrap bg-gray-700 py-1 px-3 rounded-full'>{t('statuses.pending')}</span>
                    ) : (
                        <span className='whitespace-nowrap bg-gray-700 py-1 px-3 rounded-full'>{t('statuses.active')}</span>
                    )}
                </DataCellSlim>
                {tab !== 'history' && (
                    <DataCellSlim>
                        <div className='flex relative'>
                            <button className='p-2 bg-gray-700 text-sm rounded mr-2' onClick={() => shareOrder(order)} disabled={hideActions}>
                                <ShareDotsIcon />
                            </button>

                            <button className='p-2 bg-gray-700 text-sm rounded' onClick={() => deleteOrder(order)} disabled={hideActions}>
                                <CloseMenuIcon />
                            </button>

                            <SimplePopover
                                content={
                                    <div className='flex flex-col bg-gray-900 rounded' {...popover.contentProps}>
                                        <Text className='rounded hover:cursor-pointer hover:bg-gray-700 transition px-5 py-1.5' onClick={() => modifyOrder(order)} disabled={hideActions}>
                                            {t('updateTpSl')}
                                        </Text>
                                        {!isLimitOrder(order.type) && (
                                            <Text className='rounded hover:cursor-pointer hover:bg-gray-700 transition px-5 py-1.5'  onClick={() => modifyPartialClose(order)} disabled={hideActions}>
                                                {t('closePartially')}
                                            </Text>
                                        )}
                                        {order.expertId && (
                                            <Text className='rounded hover:cursor-pointer hover:bg-gray-700 transition px-5 py-1.5' onClick={() => modifyTrade(order)} disabled={hideActions}>
                                                {t('editTrade')}
                                            </Text>
                                        )}
                                    </div>
                                }
                            >
                                <button className='p-2 ml-2 text-sm' {...popover.childrenProps}>
                                    <MoreIcon className='rotate-90' />
                                </button>
                            </SimplePopover>
                        </div>
                    </DataCellSlim>
                )}
                {tab === 'history' && (
                    <DataCellSlim>
                        <div className='flex relative'>
                            <InfoTooltip content={<Text className='text-center'>{t('share')}</Text>} positions={['top']}>
                                <button className='p-2 bg-gray-700 text-sm rounded' onClick={() => shareOrder(order)}>
                                    <ShareDotsIcon />
                                </button>
                            </InfoTooltip>
                        </div>
                    </DataCellSlim>
                )}
            </Row>
            {
                hasChildOrders && isOpen && validChildOrders?.map((childOrder) => {
                    const openTime = childOrder.openTime ? dayjs(childOrder.openTime).local().format('DD/MM/YYYY hh:mm:ss A') : '-';
                    const closeTime =
                        childOrder.closeTime || childOrder.updatedAt
                            ? dayjs(childOrder.closeTime || childOrder.updatedAt)
                                .local()
                                .format('DD/MM/YYYY hh:mm:ss A')
                            : '-';
                    const time = tab === TabType.HISTORY ? closeTime : openTime;
                    const currentPrice = childOrder?.takeProfit < childOrder?.stopLoss ? childOrder?.currentPrice?.ask : childOrder?.currentPrice?.bid;

                    return (
                        <Row key={childOrder.ticket} className='text-sm'>
                            <DataCellSlim>
                                <div className='relative group cursor-pointer hover:text-gray-500' onClick={() => copyToClipboard(childOrder.ticket)}>
                                    <div>{childOrder.ticket || '-'}</div>
                                    <div className='absolute top-[5px] right-[-20px] bg-gray-800 hidden group-hover:block'>
                                        <CopyIcon className='flex-shrink-0' width={16} height={16} />
                                    </div>
                                </div>
                            </DataCellSlim>
                            {!isExpertsDetails && (
                                <DataCellSlim className='min-w-[200px]'>
                                </DataCellSlim>
                            )}
                            <DataCellSlim></DataCellSlim>
                            <DataCellSlim className='min-w-[140px]'>{time}</DataCellSlim>
                            <DataCellSlim>
                                <span className={clsx(`whitespace-nowrap bg-grayscale-700 py-1 px-3 rounded-full ${getOrderSideColor(childOrder.type)} `)}>{childOrder.type}</span>
                            </DataCellSlim>
                            <DataCellSlim>
                                {childOrder.lots || '-'}
                            </DataCellSlim>
                            <DataCellSlim className='capitalize'>{childOrder.source === 'EXTERNAL' ? t('external') : childOrder.openedBy}</DataCellSlim>
                            <DataCellSlim>{globalRound(childOrder.openPrice, 6)}</DataCellSlim>
                            <DataCellSlim> {tab === 'history' ? globalRound(childOrder.closePrice, 6) : globalRound(currentPrice, 6) || '-'}</DataCellSlim>
                            <DataCellSlim>
                                <span className='whitespace-nowrap'>
                                    <span className={`ml-1 ${getGainLostColor(childOrder.profit)}`}>
                                        <AmountWithCurrency currency={currency} value={globalRound(childOrder.profit, 2)} />
                                    </span>
                                    <span className={`ml-1 ${getGainLostColor(childOrder.calculatedUnits)}`}>{globalRound(childOrder.calculatedUnits, 0)} {unitType}</span>
                                </span>
                            </DataCellSlim>
                            <DataCellSlim>
                                {childOrder.status === 'CLOSED' ? <span className='whitespace-nowrap bg-gray-700 py-1 px-3 rounded-full'>{t('statuses.closed')}</span> : isLimitOrder(childOrder.type) ? (
                                    <span className='whitespace-nowrap bg-gray-700 py-1 px-3 rounded-full'>{t('statuses.pending')}</span>
                                ) : (
                                    <span className='whitespace-nowrap bg-gray-700 py-1 px-3 rounded-full'>{t('statuses.active')}</span>
                                )}
                            </DataCellSlim>
                            <DataCellSlim>
                                <div className='flex relative'>
                                    <InfoTooltip content={<Text className='text-center'>{t('share')}</Text>} positions={['top']}>
                                        <button className='p-2 bg-gray-700 text-sm rounded' onClick={() => shareOrder(childOrder)}>
                                            <ShareDotsIcon />
                                        </button>
                                    </InfoTooltip>
                                </div>
                            </DataCellSlim>
                        </Row>
                    )
                })
            }
        </>
    );
}