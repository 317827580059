import styled from 'styled-components';

import clsx from 'clsx';
import { FC, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { authSelector } from '@root/shared-files/modules/auth/store';
import { CreateExpertDropdown } from '@root/modules/experts/components/create-expert-dropdown';
import { useGetExperts } from '@root/modules/experts/hooks/use-get-experts';
import { PageLinks } from '@root/shared/constants/pageLinks';
import { useOutsideClick } from '@root/shared/hooks/use-outside-click';
import { ChevronIcon } from '@root/shared/icons/chevron-icon';
import { Text } from '@root/shared/ui/typography';
import { ExpertsIcon } from '@root/shared/icons/experts-icon';

const Wrapper = styled.div`
  position: absolute;
  top: calc(100% + 10px);
  left: 0;
  min-width: 250px;
  box-shadow: 0 10px 24px rgba(12, 12, 12, 0.6);
`;

export const AssistWidget: FC = () => {
  const { t } = useTranslation('common');
  const { data } = useGetExperts();

  const maxEACount = useSelector(authSelector.maxEACount);
  const [isLimitsOpen, setIsLimitsOpen] = useState(false);
  const expertCount = data?.filter((expert) => expert.isEnabled)?.length || 0;
  const limitsRef = useRef<HTMLDivElement>(null);

  useOutsideClick(limitsRef, () => setIsLimitsOpen(false));

  return (
    <div className='bg-white bg-opacity-5 rounded text-base'>
      <div className='relative flex items-center gap-x-2 p-0.5 h-11'>
        <CreateExpertDropdown buttonText={t('header.setupSmartAssist')} />

        {/*<Button className={clsx('flex items-center gap-x-2 !px-2 !py-0.5 rounded')} onClick={handleRedirectExpert}>*/}
        {/*  <SvgSpriteIcon id='plus' />*/}
        {/*  <span className='whitespace-nowrap leading-5 py-px'>{t('header.eaWizard')}</span>*/}
        {/*</Button>*/}

        <div
          className={clsx('flex items-center gap-x-4 py-1 px-2 rounded hover:bg-white hover:bg-opacity-10 cursor-pointer transition', {
            'bg-white bg-opacity-10': isLimitsOpen,
          })}
          onClick={() => setIsLimitsOpen((prev) => !prev)}
        >
          <div className='flex items-center gap-x-2'>
            <ExpertsIcon width={20} height={20} />
            <Text className='font-bold'>
              {expertCount}/{maxEACount}
            </Text>
          </div>
          <ChevronIcon className={clsx('transform transition-200', { 'rotate-180': !isLimitsOpen })} />
        </div>
        {isLimitsOpen && (
          <Wrapper className='rounded bg-gray-800 p-4'>
            <div className='w-full flex items-center justify-between gap-x-2 mb-4'>
              <Text className='font-normal'>{t('assistWidget.limits.title')}</Text>
              <Text className='font-medium'>{maxEACount}</Text>
            </div>

            <div>
              <div className='w-full flex items-center justify-between gap-x-2'>
                <Link to={PageLinks.createExpert}>
                  <div className='flex items-center gap-x-2 p-0 hover:text-primary-400'>
                    <ExpertsIcon width={20} height={20} />
                    <Text size='sm' className='font-medium'>
                      {t('assistWidget.limits.advisors')}
                    </Text>
                  </div>
                </Link>
                <div className='flex items-center justify-end'>
                  <Text className='font-medium'>{expertCount}</Text>
                  <Text className='font-medium text-gray-600'>/{maxEACount}</Text>
                </div>
              </div>
              {/*<Text size='sm' className='text-grayscale-500 mt-4 mb-2'>*/}
              {/*  {t('assistWidget.limits.text')}*/}
              {/*</Text>*/}
              {/*<Link to={`/subscriptions`}>*/}
              {/*  <Button className='w-full'>{t('assistWidget.limits.upgrade')}</Button>*/}
              {/*</Link>*/}
            </div>
          </Wrapper>
        )}
      </div>
    </div>
  );
};
