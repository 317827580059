import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

import { subscriptionsSelector } from '@root/shared-files/modules/subscriptions/store';

import { TEST_PROVIDER_NAME } from '../constants/test-providers';
import { GET_MARKETPLACE_SUBSCRIBED, GetMarketplaceSubscribedProvidersQueryKey, GetMarketplaceTradeIdeaListData } from '../query/get-marketplace-subscribed-providers.query';
import { checkTestProvidersAvailable } from '../utils/check-test-providers-available';

export const useGetSubscribedProviders = ({ enabled = true } = {}) => {
  const subscriptionInfo = useSelector(subscriptionsSelector.userSubscriptionInfo);
  const isQAMember = useMemo(() => checkTestProvidersAvailable(subscriptionInfo?.roles), [subscriptionInfo]);

  const query = useQuery<GetMarketplaceTradeIdeaListData, Error, GetMarketplaceTradeIdeaListData, GetMarketplaceSubscribedProvidersQueryKey>(
    ['marketplace-subscribed-providers'],
    GET_MARKETPLACE_SUBSCRIBED,
    { keepPreviousData: true, staleTime: 1000 * 60 * 2, enabled },
  );

  const data = useMemo(() => {
    const list = query.data?.data?.filter((item) => (!isQAMember ? !item?.name?.includes(TEST_PROVIDER_NAME) : true)) || [];

    if (query?.data) {
      return {
        ...query.data,
        data: list,
      };
    }

    return query.data;
  }, [query, isQAMember]);

  return { ...query, data };
};
