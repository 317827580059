import * as yup from 'yup';

import { FormikHelpers } from 'formik';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { queryClient } from '@root/infra/query';
import { authSelector } from '@root/shared-files/modules/auth/store';
import { EditCopiedExpertDto } from '@root/modules/experts/dtos/edit-copied-expert.dto';
import { useRunExpert } from '@root/modules/experts/hooks/use-run-expert';
import { expertsSelector } from '@root/modules/experts/store/experts.selector';
import { IFormStatus } from '@root/shared/form';
import { notify } from '@root/shared/utils/notification';

import { CreateExpertDto } from '../dtos/create-expert.dto';
import { createExpertService } from '../services/create-expert.service';
import { expertsSlice } from '../store/experts.slice';

export const useEditCopiedMasterExpert = () => {
  const { t } = useTranslation('forex-experts');
  const dispatch = useDispatch();
  const userId = useSelector(authSelector.userId);
  const expert = useSelector(expertsSelector.editExpertModalData);

  const { runExpert } = useRunExpert();

  const initialValues = useMemo<EditCopiedExpertDto | undefined>(() => {
    if (expert) {
      return {
        id: expert.id,
        account: expert.account || '',
        symbols: expert.symbols,
      };
    }
    return undefined;
  }, [userId, expert]);

  const closeModal = useCallback(() => {
    dispatch(expertsSlice.actions.setEditExpertData(null));
  }, [dispatch]);

  const onSubmit = useCallback(
    async (values: EditCopiedExpertDto, helpers: FormikHelpers<EditCopiedExpertDto>) => {
      const result = await createExpertService(
        {
          id: values.id,
          accountId: values.account as string,
          symbols: values.symbols,
        } as unknown as CreateExpertDto,
        true,
      );
      if (result.status === 200) {
        await runExpert(result.payload);
        queryClient.invalidateQueries(['experts'], { exact: false });
        closeModal();
      } else {
        helpers.setStatus({ type: 'error', message: result.payload } as IFormStatus);
        notify({ type: 'danger', text: result.payload });
      }
    },
    [runExpert, closeModal],
  );

  const validationSchema = yup.object().shape({
    id: yup.string().required(),
    account: yup.string().required().label(t('fields.account.text')),
    symbols: yup
      .array()
      .of(yup.string().required().label(t('fields.symbols.text')))
      .min(1)
      .required()
      .label('Symbols'),
  });

  return { initialValues, onSubmit, validationSchema, closeModal, expert };
};
