import { useModifyTradeForm } from '@root/modules/experts/hooks/use-modify-trade-form';
import { IExpert } from '@root/modules/experts/types/expert';
import { IOrder } from '@root/modules/orders/types/orders';
import { Modal } from '@root/shared/ui/modal';
import { Formik, FormikProps } from 'formik';
import { FC, memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { BreakEven } from '../../break-even';
import { CreateExpertDto } from '@root/modules/experts/dtos/create-expert.dto';
import { PartialTakeProfits } from '../../partial-take-profits';
import { TrailingStop } from '../../trailing-stop';
import { Button } from '@root/shared/ui/button';
import isEqual from 'lodash/isEqual';

export type ExpertOrder = IOrder & { expertConfiguration: IExpert };
export type ModifyTradeForm = Omit<CreateExpertDto, 'meta'> & { partialAllowed: boolean };

type Props = {
    order: IOrder;
    isOpen: boolean;
    closeModal: () => void;
};

type FormProps = FormikProps<ModifyTradeForm> & { closeModal: () => void };

const ModifyTradeFormComponent: FC<FormProps> = ({ initialValues, values, closeModal, handleSubmit, isSubmitting }) => {
    const { t } = useTranslation('dashboard');

    const hasChanged = useMemo(() => !isEqual(initialValues, values), [initialValues, values]);

    return <form className="w-full flex flex-col gap-2 -mt-4" onSubmit={handleSubmit}>
        <TrailingStop use={values.trailStop.use} unitType={values.unitType} isTradeUpdate />
        {values.partialAllowed && <PartialTakeProfits isTradeUpdate initialValues={initialValues.partialClose} />}
        <BreakEven use={values.breakEven.use} levels={values.breakEven.levels} isTradeUpdate />
        <div className="w-full flex justify-between gap-x-4">
            <Button onClick={closeModal} outlined>{t('editTradeModal.cancel')}</Button>
            <Button type='submit' loading={isSubmitting} disabled={!hasChanged}>{t('editTradeModal.update')}</Button>
        </div>
    </form>;
}

export const ModifyTradeModal: FC<Props> = memo(({ isOpen, order, closeModal }) => {
    const { t } = useTranslation('dashboard');
    const { initialValues, validationSchema, onSubmit } = useModifyTradeForm({ order: order as ExpertOrder, closeModal });

    return <Modal isOpen={isOpen} onCancel={closeModal} title={t('trades.editTrade')} footer={null} className='!max-w-2xl'>
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            component={(props) => <ModifyTradeFormComponent {...props} closeModal={closeModal} />}
            enableReinitialize
        />
    </Modal>
}, (prev, next) => prev.isOpen === next.isOpen && prev.order.ticket === next.order.ticket);

ModifyTradeModal.displayName = 'ModifyTradeModal';

