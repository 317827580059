import { QueryFunction } from 'react-query';

import { GetClosedOrdersPnLServiceQuery, getClosedOrdersPnL } from '@root/modules/orders/services/get-closed-orders-pnl.service';
import { Profit } from '@root/modules/orders/types/profit';

export type GetClosedOrdersPnLQueryKey = ['fx', GetClosedOrdersPnLServiceQuery, 'orders-history-pnl'];
export type GetClosedOrdersPnLData = Profit;

export const GET_CLOSED_ORDERS_PROFIT: QueryFunction<GetClosedOrdersPnLData, GetClosedOrdersPnLQueryKey> = async ({ queryKey }) => {
  const [, params] = queryKey;

  if (!params?.userId) {
    throw new Error('Invalid query');
  }

  const result = await getClosedOrdersPnL(params);

  if (result.status === 200) {
    return result.payload;
  } else {
    throw new Error(result.payload);
  }
};
