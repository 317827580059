import { useTranslation } from 'react-i18next';
import { CalendarIcon } from '@root/shared/icons/calendar-icon';
import { ChevronIcon } from '@root/shared/icons/chevron-icon';
import clsx from 'clsx';
import de from 'date-fns/locale/de';
import es from 'date-fns/locale/es';
import fr from 'date-fns/locale/fr';
import it from 'date-fns/locale/it';
import ja from 'date-fns/locale/ja';
import no from 'date-fns/locale/nb';
import pt from 'date-fns/locale/pt';
import th from 'date-fns/locale/th';
import zh from 'date-fns/locale/zh-CN';
import { FC, useCallback, useRef, useState } from 'react';
import { ReactDatePickerProps, registerLocale } from 'react-datepicker';
import { Input } from '../input';
import { DatePicker } from './date-picker.styled';

type RangeValueType = [Date | null, Date | null];
type SingleValueType = Date | null;
type ValueType = SingleValueType | RangeValueType;

export interface DateInputProps extends Omit<ReactDatePickerProps<never, boolean>, 'value' | 'selected' | 'selectsRange' | 'onChange'> {
  withRange?: boolean;
  value: ValueType;
  placeholder?: string;
  onChange: (value: ValueType) => void;
  size?: 'base' | 'small';
  minDate?: Date;
  maxDate?: Date;
  outlined?: boolean;
  fullWidth?: boolean;
  className?: string;
}

registerLocale('it', it);
registerLocale('es', es);
registerLocale('ja', ja);
registerLocale('fr', fr);
registerLocale('de', de);
registerLocale('zh', zh);
registerLocale('th', th);
registerLocale('no', no);
registerLocale('pt', pt);

export const DateInput: FC<DateInputProps> = ({ withRange, className, placeholder, size, fullWidth = false, value, outlined, ...props }) => {
  const {
    i18n: { language },
  } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const pickerRef = useRef<any>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const selectedDate = value instanceof Array ? value[0] : value;
  const startDate = value instanceof Array ? value[0] : undefined;
  const endDate = value instanceof Array ? value[1] : undefined;

  const onCalendarOpen = useCallback(() => setIsOpen(true), []);
  const onCalendarClose = useCallback(() => setIsOpen(false), []);

  const handleIconsClick = useCallback(() => {
    pickerRef.current?.setOpen(false);
  }, []);

  return (
    <DatePicker
      locale={language}
      selected={selectedDate}
      startDate={startDate}
      onCalendarOpen={onCalendarOpen}
      onCalendarClose={onCalendarClose}
      endDate={endDate}
      showPopperArrow={false}
      dateFormat='dd.MM.yyyy'
      selectsRange={withRange}
      placeholderText={placeholder}
      wrapperClassName={fullWidth ? 'w-full' : 'w-auto'}
      ref={pickerRef}
      customInput={
        <Input
          size={size}
          style={{ minWidth: fullWidth ? '100%' : 280 }}
          prefixClassName={clsx({ '!z-2 cursor-text': isOpen })}
          suffixClassName={clsx({ '!z-2 cursor-text': isOpen })}
          prefix={
            <div onClick={handleIconsClick}>
              <CalendarIcon />
            </div>
          }
          className={clsx(className, { '!bg-transparent border !border-gray-700 active:!border-gray-100 focus:!border-gray-100': outlined })}
          suffix={
            <div onClick={handleIconsClick}>
              <ChevronIcon
                className={clsx('transform transition-transform', {
                  'text-xs': size === 'small',
                  'rotate-180': !isOpen,
                })}
              />
            </div>
          }
          ref={inputRef}
        />
      }
      {...props}
    />
  );
};
