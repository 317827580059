import clsx from 'clsx';
import { useFormikContext } from 'formik';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { SymbolType } from '@root/modules/accounts/enums';
import { ExpertSymbolSelect } from '@root/modules/experts/components/expert-symbol-select';
import { CreateExpertDto } from '@root/modules/experts/dtos/create-expert.dto';
import { ProviderMarketTypeToSymbolTypeMap } from '@root/modules/experts/helpers/provider-market-type-to-symbol-type-map';
import { useCreateAccountStep } from '@root/modules/experts/hooks/use-create-account-step';
import { useProviderTraders } from '@root/modules/marketplace/hooks/use-provider-traders';
import { PageLinks } from '@root/shared/constants/pageLinks';
import { SelectField } from '@root/shared/form/fields';
import { Button } from '@root/shared/ui/button';
import { Text, Title } from '@root/shared/ui/typography';

import { StepProps } from '../types/steps';
import { FieldHint } from '@root/shared/ui/form';

export const Accounts: FC<
  StepProps & {
    nextPageUrl?: string;
    isPartialEdit?: boolean;
  }
> = ({ nextPageUrl = PageLinks.createExpert, isPartialEdit }) => {
  const { t } = useTranslation('forex-experts');
  const { values } = useFormikContext<CreateExpertDto>();
  const { traders } = useProviderTraders();

  const allowedSymbolType = useMemo(() => {
    if (values.strategy.type === 'signal' && values.providerId) {
      const selectedProvider = traders.find(({ id }) => id === values.providerId);
      if (selectedProvider) return ProviderMarketTypeToSymbolTypeMap[selectedProvider.market as SymbolType];
    }
    return null;
  }, [traders, values.strategy.type, values.providerId]);

  const [{ symbolOptions, account, noAccounts, accountOptions, symbols, selectAllSymbols, removeSymbolByIndex, clearAllSymbols }, { onAccountCreate, onSymbolChange }] =
    useCreateAccountStep(values.account, allowedSymbolType);

  return (
    <div>
      {!isPartialEdit && (
        <Title level={7} className='mb-4'>
          {t('steps.account.accountConfig')}
        </Title>
      )}
      {noAccounts && (
        <div className='mb-4'>
          <Text size='sm' className='mb-4'>
            {t('cases.noAccount')}
          </Text>
          {!isPartialEdit && (
            <Button outlined onClick={() => onAccountCreate(nextPageUrl)}>
              {t('actions.connectAccount')}
            </Button>
          )}
        </div>
      )}
      {!noAccounts && (
        <div className='flex items-start flex-col sm:flex-row md:flex-col xl:flex-row'>
          <div className='flex-1 w-full'>
            <SelectField
              options={accountOptions}
              isDisabled={!accountOptions?.length}
              name='account'
              wrapperClassName={clsx({'mb-6': !isPartialEdit, 'mb-2': isPartialEdit})}
              label={t('fields.account.label')}
              placeholder={t('fields.account.placeholder')}
            />
          </div>
          {!isPartialEdit && (
            <Button outlined
                    className='ml-auto mb-4 sm:mt-[27px] md:mt-0 sm:ml-4 md:ml-auto sm:mb-0 md:mb-4 xl:mt-[27px] xl:mb-0 xl:ml-4'
                    onClick={onAccountCreate}>
              {t('actions.connectAccount')}
            </Button>
          )}
        </div>
      )}
      {symbolOptions?.length < 2 && <FieldHint variant='danger'>{t('accountNotSupported', { symbolType: t('symbolType.' + allowedSymbolType) })}</FieldHint>}
      {symbolOptions?.length > 1 && !isPartialEdit && (
        <Title level={7} className='mb-4'>
          {t('steps.account.pairConfig')}
        </Title>
      )}
      {symbolOptions?.length > 1 && <ExpertSymbolSelect
          symbols={symbols}
          selectAllSymbols={selectAllSymbols}
          removeSymbolByIndex={removeSymbolByIndex}
          clearAllSymbols={clearAllSymbols}
          onSymbolChange={onSymbolChange}
          symbolOptions={symbolOptions}
          isDisabled={!account}
          wrapperClassName={clsx({'mb-6': !isPartialEdit, 'mb-2': isPartialEdit})}
      />}
    </div>
  );
};
