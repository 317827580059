import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { CreateExpertDto } from '@root/modules/experts/dtos/create-expert.dto';
import { SelectField, TextField } from '@root/shared/form';
import { IconLabel } from '@root/shared/ui/form';
import { InfoTooltip } from '@root/shared/ui/info-tooltip';
import { Text, Title } from '@root/shared/ui/typography';
import { useCreateExpertFieldOptions } from '../hooks/use-create-expert-options';
import { SelectButton } from '@root/shared/ui/select-button/select-button';
import { useFormikContext } from 'formik';
import { useSignalTakeProfitFields } from '../hooks/use-signal-take-profit-fields';
import { InfoTooltipIcon } from '@root/shared/icons/info-tooltip-icon';

export const SignalStopLossTakeProfit: FC<{ isPartialEdit?: boolean; }> = ({ isPartialEdit }) => {
  const { values, setFieldValue } = useFormikContext<CreateExpertDto>();
  const { t } = useTranslation('forex-experts');

  const options = useCreateExpertFieldOptions(values?.unitType);

  const type = values.signalSlTp.type;
  const profitCalculation = values.signalSlTp.profitCalculationType;
  const slType = values.signalSlTp.slType;

  const { onCalculationTypeChange, onRRRatioChange, onSlTpTypeChange } = useSignalTakeProfitFields(isPartialEdit);

  return (
    <div>
      <Title level={7} className='mb-4'>
        {t('steps.stopLossTakeProfit.title')}
      </Title>

      <Text size='sm' className='text-gray-500 mb-4'>
        {t('fields.signalStopLossTakeProfit.type.description')}
      </Text>
      <SelectField
        name='signalSlTp.type'
        label={
          <IconLabel
            icon={
              <InfoTooltip content={<Text size='sm'>{t('fields.signalStopLossTakeProfit.type.description')}</Text>}>
                <InfoTooltipIcon />
              </InfoTooltip>
            }
          >
            {t('fields.signalStopLossTakeProfit.type.label')}
          </IconLabel>
        }
        wrapperClassName='mb-4'
        placeholder={t('fields.signalStopLossTakeProfit.type.placeholder')}
        options={options.signalStopLossTakeProfit}
        onChange={onSlTpTypeChange}
      />
      <div className='grid sm:grid-cols-2 gap-4'>
        {['0', '1'].includes(type) && (
          <TextField
            name='signalSlTp.fixedSl'
            type='number'
            label={
              <IconLabel
                icon={
                  <InfoTooltip content={<Text size='sm'>{t('fields.signalStopLossTakeProfit.fixedSl.description')}</Text>}>
                    <InfoTooltipIcon />
                  </InfoTooltip>
                }
              >
                {t('fields.signalStopLossTakeProfit.fixedSl.label')}
              </IconLabel>
            }
            placeholder={t('fields.signalStopLossTakeProfit.fixedSl.placeholder')}
          />
        )}
        {['0'].includes(type) && (
          <TextField
            name='signalSlTp.fixedTp'
            type='number'
            label={
              <IconLabel
                icon={
                  <InfoTooltip content={<Text size='sm'>{t('fields.signalStopLossTakeProfit.fixedTp.description')}</Text>}>
                    <InfoTooltipIcon />
                  </InfoTooltip>
                }
              >
                {t('fields.signalStopLossTakeProfit.fixedTp.label')}
              </IconLabel>
            }
            placeholder={t('fields.signalStopLossTakeProfit.fixedTp.placeholder')}
          />
        )}
        {['1'].includes(type) && (
          <TextField
            name='signalSlTp.tpRatio'
            type='number'
            label={
              <IconLabel
                icon={
                  <InfoTooltip content={<Text size='sm'>{t('fields.signalStopLossTakeProfit.tpRatio.description')}</Text>}>
                    <InfoTooltipIcon />
                  </InfoTooltip>
                }
              >
                {t('fields.signalStopLossTakeProfit.tpRatio.label')}
              </IconLabel>
            }
            placeholder={t('fields.signalStopLossTakeProfit.tpRatio.placeholder')}
          />
        )}
        {
          ['4'].includes(type) && <>
            <SelectField
              name='signalSlTp.profitCalculationType'
              label={t('fields.signalStopLossTakeProfit.profitCalculationType.label')}
              placeholder={t('fields.signalStopLossTakeProfit.profitCalculationType.placeholder')}
              options={options.smartEntryProfitCalculationType}
              wrapperClassName='sm:col-span-2'
              onChange={onCalculationTypeChange}
            />
            <div className='w-full col-span-2'>
              <div className="flex justify-start items-center gap-4 mb-2">
                <SelectButton type='button' selected={slType === '0'} onClick={() => setFieldValue('signalSlTp.slType', '0')}>
                  <Text size='sm'>{options.slTypeOptions[0].label}</Text>
                </SelectButton>
                <SelectButton type='button' selected={slType === '1'} onClick={() => setFieldValue('signalSlTp.slType', '1')}>
                  <Text size='sm'>{options.slTypeOptions[1].label}</Text>
                </SelectButton>
              </div>
              {slType === '0' && (
                <Text size='sm' className='text-grayscale-400'>{
                  <Trans
                    i18nKey='forex-experts:fields.signalStopLossTakeProfit.slType.slByPriceDescription'
                    components={{ a: <a className='text-primary-400 visited:text-primary-400'></a> }}
                  />
                }</Text>
              )}
              {slType === '1' && (
                <Text size='sm' className='text-grayscale-400'>{
                  <Trans
                    i18nKey='forex-experts:fields.signalStopLossTakeProfit.slType.slByPipsDescription'
                    components={{ a: <a className='text-primary-400 visited:text-primary-400'></a> }}
                    values={{unitType: values?.unitType}}
                  />
                }</Text>
              )}
            </div>
            {
              profitCalculation === '0' ?
                <SelectField
                  name='signalSlTp.rrRatio'
                  label={
                    <IconLabel
                      icon={
                        <InfoTooltip content={<Text size='sm'>{t('fields.signalStopLossTakeProfit.rrRatio.description')}</Text>}>
                          <InfoTooltipIcon />
                        </InfoTooltip>
                      }
                    >
                      {t('fields.signalStopLossTakeProfit.rrRatio.label')}
                    </IconLabel>
                  }
                  placeholder={t('fields.signalStopLossTakeProfit.rrRatio.placeholder')}
                  options={options.rrRatioOptions}
                  onChange={onRRRatioChange}
                /> : <TextField
                  name='signalSlTp.fixedTp'
                  type='number'
                  label={
                    <IconLabel
                      icon={
                        <InfoTooltip content={<Text size='sm'>{t('fields.signalStopLossTakeProfit.smartFixedTp.description')}</Text>}>
                          <InfoTooltipIcon />
                        </InfoTooltip>
                      }
                    >
                      {t('fields.signalStopLossTakeProfit.smartFixedTp.label')}
                    </IconLabel>
                  }
                  placeholder={t('fields.signalStopLossTakeProfit.smartFixedTp.placeholder')}
                  suffix={values?.unitType}
                />
            }
          </>
        }
      </div>
    </div>
  );
};
