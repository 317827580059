import styled from 'styled-components';

import clsx from 'clsx';
import { useField } from 'formik';
import { FC, PropsWithChildren } from 'react';

import { Button } from '@root/shared/ui/button';
import { Field, FieldHint } from '@root/shared/ui/form';

export type CheckboxFieldProps = PropsWithChildren<{
  name: string;
  value: string;
}>;

export const ButtonRadioGroup = styled.div`
  display: flex;
  gap: 5px;
`;

const StyledButton = styled(Button)`
  border-radius: 13px;
  border: 1px solid;
  font-size: 13px;
  font-weight: 500;
  padding: ${({ theme }) => `${theme.spacing(2)}px ${theme.spacing(2)}px`};

  @media screen and (min-width: 640px) {
    padding: ${({ theme }) => `${theme.spacing(2)}px ${theme.spacing(4)}px`};
  }
`;

export const ButtonRadioField: FC<CheckboxFieldProps> = ({ name, value, children }) => {
  const [inputProps, meta] = useField({ name, value, type: 'radio' });

  return (
    <Field>
      <div className='relative inline-flex'>
        <StyledButton variant={inputProps.checked ? 'primary' : 'muted'} outlined type='button'>
          {children}
        </StyledButton>
        <input type='radio' className='absolute top-0 left-0 right-0 bottom-0 opacity-0 cursor-pointer' {...inputProps} />
      </div>
      <FieldHint variant='danger'>{!!meta.error && meta.touched && meta.error}</FieldHint>
    </Field>
  );
};
