import '@3lgn/shared/dist/constants/roles';

import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

import { subscriptionsSelector } from '@root/shared-files/modules/subscriptions/store';

import { TEST_PROVIDER_NAME } from '../constants/test-providers';
import { GET_MARKETPLACE_TRADE_IDEA_LIST, GetMarketplaceTradeIdeaListData, GetMarketplaceTradeIdeaListQueryKey } from '../query/get-marketplace-trade-idea-list.query';
import { checkTestProvidersAvailable } from '../utils/check-test-providers-available';

export const useGetMarketplaceTradeIdeaList = (params, enabled = true) => {
  const subscriptionInfo = useSelector(subscriptionsSelector.userSubscriptionInfo);

  const isQAMember = useMemo(() => checkTestProvidersAvailable(subscriptionInfo?.roles), [subscriptionInfo]);

  const result = useQuery<GetMarketplaceTradeIdeaListData, Error, GetMarketplaceTradeIdeaListData, GetMarketplaceTradeIdeaListQueryKey>(
    ['marketplace-trade-idea-list', params],
    GET_MARKETPLACE_TRADE_IDEA_LIST,
    { keepPreviousData: true, staleTime: 1000 * 60 * 3, enabled },
  );

  const data = useMemo(() => {
    const list = result.data?.data?.filter((item) => (!isQAMember ? !item?.name?.includes(TEST_PROVIDER_NAME) : true));

    if (result?.data) {
      return {
        ...result.data,
        data: list,
      };
    }

    return result.data;
  }, [result, isQAMember]);

  return { ...result, data };
};
