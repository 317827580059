import dayjs from 'dayjs';

import { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

import { useGetOpenOrders } from '@root/modules/orders/hooks/use-open-orders';
import { GET_CLOSED_ORDERS_PROFIT } from '@root/modules/orders/queries/get-orders-history-pnl.query';
import { getOrdersHistoryService } from '@root/modules/orders/services/get-orders-history.service';
import { authSelector } from '@root/shared-files/modules/auth/store';
import { globalRound } from '@root/shared/utils/number/round';
import { Id } from '@root/shared/utils/types';

type Props = {
  selectedBroker: string;
  mtType?: string;
  expertId?: string;
};

export const useGetOrdersProfit = ({ selectedBroker, mtType, expertId }: Props) => {
  const userId = useSelector(authSelector.userId);

  const [todayPnl, setTodayPnl] = useState<{ profit: number; pips: number }>({ profit: 0, pips: 0 });
  const { openOrders } = useGetOpenOrders({ enabled: true, selectedBroker, mtType });

  const { data: closedOrdersData } = useQuery(
    [
      'fx',
      {
        userId: userId as string,
        accounts: selectedBroker || '',
        expertId,
      },
      'orders-history-pnl',
    ],
    GET_CLOSED_ORDERS_PROFIT,
    {
      enabled: true,
      staleTime: 1000 * 60 * 10,
      keepPreviousData: true,
    },
  );

  const openOrdersData = useMemo(() => {
    const ordersByExpert = expertId ? (openOrders || []).filter((item) => item.expertId === expertId) : openOrders || [];

    const { profit, pips } = ordersByExpert.reduce(
      (acc, item) => ({
        profit: acc.profit + item.profit,
        pips: acc.pips + item.calculatedUnits,
      }),
      { profit: 0, pips: 0 },
    );

    return {
      profit: globalRound(profit, 2),
      pips: globalRound(pips || 0, 2),
    };
  }, [expertId, openOrders]);

  const totalOrdersData = useMemo(
    () => ({
      profit: globalRound(openOrdersData.profit + (closedOrdersData?.profit || 0), 2),
      pips: globalRound(openOrdersData.pips + (closedOrdersData?.pips || 0), 2),
    }),
    [openOrdersData, closedOrdersData],
  );

  useEffect(() => {
    const params = {
      size: 1000,
      orderDirection: 'DESC',
      orderBy: 'closeTime',
      page: 1,
      userId: userId as Id,
      accounts: selectedBroker,
      expertId,
    };

    if (selectedBroker || expertId) {
      (async () => {
        const response = await getOrdersHistoryService(params);
        if (response.status === 200) {
          const todayOrders = response.payload.orders.filter((order) => {
            const filterByDate = dayjs(order.closeTime).isSame(dayjs(), 'day');
            const filterByAccountId = selectedBroker && selectedBroker !== 'all' ? order.accountId === selectedBroker : true;
            const filterByExpertId = expertId ? order.expertId === expertId : true;
            return filterByDate && filterByAccountId && filterByExpertId;
          });

          const { profit, pips } = todayOrders.reduce(
            (acc, item) => ({
              profit: acc.profit + item.profit,
              pips: acc.pips + item.calculatedUnits,
            }),
            { profit: 0, pips: 0 },
          );

          setTodayPnl({
            profit: globalRound(profit, 2),
            pips: globalRound(pips, 0),
          });
        }
      })();
    }
  }, [expertId, selectedBroker, userId]);

  return { openOrdersData, closedOrdersData, totalOrdersData, todayPnl };
};
